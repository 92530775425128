import React, {Component} from "react";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import PhoneInput from "react-phone-input-2";
import {isEmpty} from "../utils/commonFunctions";
import {InputTextarea} from "primereact/inputtextarea";
import {Card} from "primereact/card";

class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            contacts: [],
            dataTableSelectValue: [],
            actionToConfirm: null,
            recipientActionType: null,
            addRecipientDialog: false,
            updateRecipientDialog: false,
            deleteRecipientDialog: false,
            sendMessageDialog: false,
            recipient: {name: '', last_name: '', phone: ''},
            sendMessageText: '',
            countMessageText: 0,
            processingAction: false,
            errors: {},
            displayConfirm: false,
        }

        this.changeRecipientHandler = this.changeRecipientHandler.bind(this);
        this.sendMessageHandler = this.sendMessageHandler.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.callRecipientList = this.callRecipientList.bind(this);
        this.addRecipient = this.addRecipient.bind(this);
        this.updateRecipient = this.updateRecipient.bind(this);
        this.deleteRecipient = this.deleteRecipient.bind(this);

        this.validate = this.validate.bind(this);
        this.worxflowServices = new WorxflowServices();
    }

    componentDidMount() {
        this.callRecipientList();
    }

    callRecipientList() {
        this.worxflowServices.callRecipientList().then(resp => {
            if (resp.status === 200) {
                this.setState({contacts: resp.data});
            }
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });

        this.setState({chargedYet: true});
    }

    changeRecipientHandler = event => {
        const {recipient, errors} = this.state;
        recipient[event.target.name] = event.target.value;

        if (errors.recipient !== undefined && errors.recipient[event.target.name] !== undefined) {
            errors.recipient[event.target.name] = undefined;
        }
        this.setState({recipient, errors});
    };
    changePhoneHandler = event => {
        const {recipient, errors} = this.state;
        recipient.phone = event;
        if (errors.recipient?.phone !== undefined) {
            errors.recipient.phone = undefined;
        }
        this.setState({recipient, errors});
    };
    changeMessageTextHandler = event => {
        const {errors} = this.state;
        if (errors.recipient !== undefined && errors.recipient[event.target.name] !== undefined) {
            errors.recipient[event.target.name] = undefined;
        }
        const countMessageText = event.target.value.length;
        if(countMessageText < 161){
            this.setState({
                [event.target.name]: event.target.value,
                countMessageText,
                errors});
        }
    };
    showModal = (modal, type) => () => {
        /*validation before show modal*/
        const {recipient, dataTableSelectValue} = this.state;
        if (modal === 'deleteRecipientDialog') {
            const errors = this.validate(modal);
            if (Object.keys(errors).length === 0) {
                this.setState({
                    [modal]: true,
                    recipientActionType: type
                });
            }
        } else if (modal === 'sendMessageDialog') {
            this.setState({
                [modal]: true,
                recipientActionType: type
            });
        } else {
            if (type === 'new' || dataTableSelectValue.length === 1) {
                if (type === 'update') {
                    recipient.name = dataTableSelectValue[0].name;
                    recipient.last_name = dataTableSelectValue[0].last_name;
                    recipient.phone = dataTableSelectValue[0].phone;
                }
                this.setState({
                    [modal]: true,
                    recipientActionType: type,
                    recipient
                });
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Select only one row.'
                });
            }
        }
    }
    hideModal = modal => () => {
        if (modal !== "displayConfirm") {
            this.setState({recipient: {name: '', last_name: '', phone: ''}});
        }
        this.setState({
            [modal]: false,
            displayConfirm: false,
            sendMessageText: '',
            errors: {},
        });
    }
    confirmationDialog = (nameModal, showModal, action) => {
        /*validation before to confirm*/
        const errors = this.validate(nameModal);
        if (Object.keys(errors).length === 0) {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        } else {
            this.setState({errors});
        }
    }

    addRecipient() {
        this.setState({processingAction: true});
        const {recipient} = this.state;
        const params = {
            name: recipient.name,
            last_name: recipient.last_name,
            phone: recipient.phone
        };
        this.worxflowServices.addRecipientList(params).then(resp => {
            this.setState({addRecipientDialog: false, processingAction: false, displayConfirm: false});
            this.callRecipientList();
        }).catch(error => {
            this.setState({processingAction: false});
            console.error("Error en el servicio: ", error);
        });
    }

    updateRecipient() {
        this.setState({processingAction: true});
        const {dataTableSelectValue, recipient} = this.state;
        const params = {
            name: recipient.name,
            last_name: recipient.last_name,
            phone: recipient.phone
        };
        this.worxflowServices.updateRecipientList(dataTableSelectValue[0].id,params).then(resp => {
            this.setState({
                addRecipientDialog: false,
                dataTableSelectValue: [],
                processingAction: false,
                displayConfirm: false
            });
            this.callRecipientList();
        }).catch(error => {
            this.setState({addRecipientProcessing: false});
            console.error("Error en el servicio: ", error);
        });
    }

    deleteRecipient() {
        this.setState({processingAction: true});
        const {dataTableSelectValue} = this.state;
        let status = true;
        dataTableSelectValue.forEach(element => {
            this.worxflowServices.deleteRecipientList(element.id).then(resp => {
            }).catch(error => {
                this.setState({processingAction: false});
                status = false;
                console.error("Error en el servicio: ", error);
            });
        });
        setTimeout(() => {
            if(status){
                this.toast.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Rows deleted!',
                    life: 5000
                });
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Error in services try later!',
                    life: 5000
                });
            }
            this.setState({
                deleteRecipientDialog: false,
                dataTableSelectValue: [],
                processingAction: false,
                displayConfirm: false
            });
            this.callRecipientList();
        }, 3000);
    }

    sendMessageHandler = () => {
        this.setState({processingAction: true});

        const {dataTableSelectValue, sendMessageText} = this.state;
        const contactsID = [];
        dataTableSelectValue.forEach(element => {
            contactsID.push(element.id);
        });
        const params={
            ids: contactsID,
            txt: sendMessageText,
        };

        this.worxflowServices.sendSMSRecipient(params).then(resp => {
            this.toast.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Messages sent!',
                life: 5000
            });
            this.setState({
                sendMessageDialog: false,
                dataTableSelectValue: [],
                processingAction: false,
                displayConfirm: false
            });
            this.callRecipientList();
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Problem in service!',
                life: 5000
            });
            this.setState({processingAction: false});
            console.error("Error en el servicio: ", error);
        });

    };

    validate(nameModal) {
        const {recipient, dataTableSelectValue,sendMessageText} = this.state;
        let errors = {};
        errors.recipient = {};
        switch (nameModal) {
            case 'addRecipientDialog':
            case 'updateRecipientDialog':
                if (isEmpty(recipient.name))
                    errors.recipient.name = 'Name name is mandatory.';
                if (isEmpty(recipient.last_name))
                    errors.recipient.last_name = 'Last Name is mandatory.';
                if (isEmpty(recipient.phone))
                    errors.recipient.phone = 'Phone is mandatory.';
                break;
            case 'deleteRecipientDialog':
                if (dataTableSelectValue.length < 1) {
                    errors.recipient.dataSelected = 'No data selected.';
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: errors.recipient.dataSelected
                    });
                }
                break;
            case 'sendMessageDialog':
                if (isEmpty(sendMessageText) || sendMessageText.length < 3) {
                    errors.recipient.sendMessageText = 'Message is mandatory.';
                }
                break;
            default:
                break
        }
        errors = Object.keys(errors.recipient).length === 0 ? {} : errors;
        return errors;
    }

    render() {
        const {errors} = this.state;

        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processingAction}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processingAction}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw'}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" className={this.state.deleteRecipientDialog ? "p-button-danger" : ""}
                        onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction} />
            </div>
        );

        const addRecipientDialog = (
            <Dialog header={this.state.recipientActionType === 'new' ? "Add Recipient" : "Update Recipient"}
                    visible={this.state.addRecipientDialog} modal
                    style={{width: '600px'}} contentStyle={{height: 275}}
                    footer={dialogFooter('addRecipientDialog',
                        this.state.recipientActionType === 'new' ? this.addRecipient : this.updateRecipient,
                        this.state.recipientActionType === 'new' ? 'Add' : 'Update')}
                    onHide={this.hideModal('addRecipientDialog')}>
                <div className="p-fluid datail-transaction pb-30">
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Phone:</p>
                        <div className="col">
                            <PhoneInput placeholder="Phone"
                                        value={this.state.recipient.phone} enableSearch={true}
                                        onChange={this.changePhoneHandler}
                                        className={errors?.recipient?.phone !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.phone !== undefined ?
                                <small className="offline">{errors?.recipient?.phone}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Name:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.name} name="name"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.name !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.name !== undefined ?
                                <small className="offline">{errors?.recipient?.name}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Last Name:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.last_name} name="last_name"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.last_name !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.last_name !== undefined ?
                                <small className="offline">{errors?.recipient?.last_name}</small>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const deleteRecipientDialog = (
            <Dialog header="Delete Recipient" visible={this.state.deleteRecipientDialog} modal
                    style={{width: '550px'}} contentStyle={{height: 120}}
                    footer={dialogFooter('deleteRecipientDialog', this.deleteRecipient, 'Confirm Delete')}
                    onHide={this.hideModal('deleteRecipientDialog')}>
                <div className="datail-transaction">
                    <div className="grid">
                        <div className="col-12">
                            <ul className="a" style={{listStyle: 'circle', paddingLeft: 15}}>
                                {this.state.dataTableSelectValue.map((element, i) => {
                                    return (<li key={element.id} style={{paddingBottom: 12}}>
                                        <b>Name:</b>{element.name} &nbsp;&nbsp;&nbsp;&nbsp;<b>Last
                                        Name:</b>{element.last_name} &nbsp;&nbsp;&nbsp;&nbsp;
                                        <b>Phone:</b>{element.phone}
                                    </li>)
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const sendMessagesDialog = (
            <Dialog header="Detail Messages" visible={this.state.sendMessageDialog} modal
                    style={{width: '550px'}} contentStyle={{height: 240}}
                    footer={dialogFooter('sendMessageDialog', this.sendMessageHandler, 'Send Message')}
                    onHide={this.hideModal('sendMessageDialog')}>
                <div className="datail-transaction">
                    <div className="grid">
                        <div className="col-12">
                            <ul className="a" style={{listStyle: 'circle', paddingLeft: 15}}>
                                <li>{this.state.dataTableSelectValue.map((element, i) => {
                                    return (<p key={element.id}><b>Phone:</b>{element.phone}, &nbsp;&nbsp;</p>)
                                })}</li>
                            </ul>
                        </div>
                        <div className="col-3">
                            <span className="textBold">Message:</span>
                            {errors?.recipient?.sendMessageText !== undefined ?
                                <small className="p-messages p-messages-error">{errors?.recipient?.sendMessageText}</small>
                                : ""
                            }
                        </div>
                        <div className="col-9 ta-right">
                            <InputTextarea value={this.state.sendMessageText} rows={4} cols={40} name="sendMessageText"
                                           onChange={this.changeMessageTextHandler}/>
                            <small className="p-messages">{this.state.countMessageText}/160</small>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div className="headButtons">
                                <Button label="Add" onClick={this.showModal('addRecipientDialog', 'new')}
                                        className="p-button-success"/>
                                <Button label="Update" onClick={this.showModal('addRecipientDialog', 'update')}
                                        className="p-button-info"/>
                                <Button label="Delete" onClick={this.showModal('deleteRecipientDialog')}
                                        className="p-button-danger"/>
                                <Button label="Send Message" onClick={this.showModal('sendMessageDialog')}/>
                            </div>
                        </div>
                        <div className="col-12">
                            <DataTable value={this.state.contacts} selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}
                                       reorderableColumns={true} paginator={true} rows={50}
                                       resizableColumns={true} dataKey="id">
                                <Column selectionMode="multiple" headerStyle={{width: 50}}
                                        className="ta-center"></Column>
                                <Column field="name" header="Name" className="ta-right" sortable/>
                                <Column field="last_name" header="Last Name" className="ta-right" sortable/>
                                <Column field="phone" header="Phone" className="ta-right" sortable/>
                            </DataTable>
                        </div>
                    </div>
                </Card>
                {confirmDialog}
                {addRecipientDialog}
                {deleteRecipientDialog}
                {sendMessagesDialog}
            </div>
        )
    }
}

export default Contacts;
