import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {UsuarioService} from "../../service/login/UsuarioService";
import {Card} from "primereact/card";
import {Messages} from "primereact/messages";

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            phone: '',

            catPhones: [],
            catCountries: [],
            catNationalities: [],
            catIdTypes: [],
        };
    }

    componentDidMount() {
        const profile = JSON.parse(localStorage.getItem('profile'));
        if (profile !== undefined && profile !== null) {
            const dataProfile = profile.profile;
            this.setState({
                firstName: profile.first_name != null ? profile.first_name : '',
                lastName: profile.last_name != null ? profile.last_name : '',
                phone: dataProfile.phone_number != null ? dataProfile.phone_number : '',
            });
        }
    }

    submitHandler = e => {
        e.preventDefault();
        const self = this;
        const {
            firstName,
            lastName,
            phone,
        } = this.state;
        const profile = {
            first_name: firstName,
            last_name: lastName,
            phone_number: phone,
        };
        this.setState({chargedYet: false});
        const worxflowServices = new WorxflowServices();
        worxflowServices.updateProfile(profile).then(resp => {
            if (resp.status === 'success') {
                const usuarioService = new UsuarioService();
                usuarioService.getUsuarioLogueo().then(resp => {
                    this.setState({
                        firstName: resp.data.first_name,
                        lastName: resp.data.last_name,
                        phone: resp.data?.phone_number !== undefined ? resp.data?.phone_number : phone,
                    });
                    localStorage.setItem('profile', JSON.stringify(resp.data));
                }).catch(error => {
                    console.log(error);
                });
                self.msgs.show({severity: 'success', summary: 'Sucess', detail: 'Profile saved'});
            }
            this.setState({chargedYet: true});
        }).catch(error => {
            self.msgs.show({severity: 'error', summary: 'Error', detail: 'Error save profile'});
            this.setState({chargedYet: true});
        });

    };

    changeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    };

    render() {
        const {
            firstName,
            lastName,
            phone,
        } = this.state;

        return (
            <Card>
                <Messages ref={(el) => this.msgs = el} />
                <div className="col-12">
                    <h1>Profile</h1>
                </div>
                <div className="grid p-fluid">
                    <div className="col-12 sm:col-6 xl:col-4">
                        <label>First Name</label>
                        <InputText
                            placeholder="First Name"
                            name="firstName"
                            onChange={this.changeHandler}
                            value={firstName}
                        />
                    </div>
                    <div className="col-12 sm:col-6 xl:col-4">
                        <label>Last Name</label>
                        <InputText
                            placeholder="Last Name"
                            name="lastName"
                            onChange={this.changeHandler}
                            value={lastName}
                        />
                    </div>
                    <div className="col-12 sm:col-6 xl:col-4">
                        <label>Phone</label>
                        <InputText
                            placeholder="Phone"
                            name="phone"
                            onChange={this.changeHandler}
                            value={phone}
                        />
                    </div>
                    <div className="col-12 ta-center">
                        <div className="grid">
                            <div className="col-4 col-offset-4">
                                <Button label="Submit" style={{marginBottom: '10px'}} onClick={this.submitHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        )
    }

}

export default Profile;
