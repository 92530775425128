import axios from 'axios';

export class AuthService {
    getConfig = params => {
        return {
            params,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    };

    loginService = (username, password) => {

        let params = {
            username: username,
            password: password
        };
        //REACT_APP_ENDPOINT_USERS
        return axios
            .post(
                `${process.env.REACT_APP_ENDPOINT_OAUTH}/api/token/`,
                params,
                this.getConfig(),
            )
            .then(resp => {
                const token = this.parseJwt(resp.data.access);
                localStorage.setItem("access_token", resp.data.access);
                localStorage.setItem("expires_date", token.exp);
                localStorage.setItem("refresh_token", resp.data.refresh);
            });
    }

    parseJwt(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

}
