import React, {Component} from 'react';
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import "./layout-blue.css"

export default class ConfirmForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            urlLogo: '',
            newPassword: '',
            confirmPassword: '',
            inProcess: false,
            errors: {},
        }
        this.worxflowServices = new WorxflowServices();
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }

    componentDidMount() {
    }
    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitHandler(e);
        }
    }
    submitHandler = (e) => {
        e.preventDefault();
        this.setState({inProcess: true});
        const validate = {
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        }
        const errors = this.validate(validate);
        if (Object.keys(errors).length === 0) {
            const params = {
                user: this.props.match.params['idUser'],
                token: this.props.match.params['token'],
                new_password1: this.state.newPassword,
                new_password2: this.state.confirmPassword
            }
            this.worxflowServices.resetPasswordService(params).then(resp => {
                if (resp["status code"] === 200) {
                    this.toast.show({severity: 'success', summary: 'Success email send', detail: resp["message"]});
                    setTimeout(() => {
                        this.props.history.push("/login")
                    }, 5000);
                }
            }).catch(error => {
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.toast.show({severity: 'error', summary: 'Error', detail: errors.newPassword + " " + errors.confirmPassword});
            this.setState({inProcess: false});
        }
        this.setState({errors});
        console.log("submit forgot password");
    }
    validate = (data) => {
        let errors = {};
        if (!data.newPassword) {
            errors.newPassword = 'Password is mandatory.';
        }
        if (!data.confirmPassword) {
            errors.confirmPassword = 'Confirm password is mandatory.';
        }
        if(data.newPassword !== data.confirmPassword){
            errors.confirmPassword = 'Password and confirm password need do not equals.';
        }
        return errors;
    }
    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({[event.target.name]: event.target.value, errors});
    }

    render() {
        return (
            <div className="login-body">
                <Toast ref={(el) => this.toast = el} />
                <div className="col-12 rightContainer">
                    <div className="col-12 rightContainer">
                        <div className="col-12" style={{
                            position: 'relative',
                            height: '10%',
                            alignItems: 'center',
                            color: "white",
                            fontSize: '24px'
                        }}>
                            <h1 style={{
                                position: 'absolute',
                                top: '20%',
                                left: '25%',
                                marginRight: '5%',
                                fontSize: '20px',
                                color: "white"
                            }}>

                            </h1>
                        </div>
                        <div className="col-12" style={{textAlign: 'center'}}>
                            <div className="col-12">
                                <img alt="Logo" src={this.state.urlLogo} className="topbar-logo"/>
                            </div>
                        </div>
                    </div>
                    <div className="card login-panel p-fluid">
                        <div className="login-panel-content">
                            <div className="grid">
                                <div className="col-3" style={{textAlign: 'left'}}>
                                    <img src="/assets/layout/images/login/icon-login.svg" alt="avalon-react"/>
                                </div>
                                <div className="col-9 mt-10" style={{textAlign: 'left'}}>
                                    <h2 className="welcome-text">Reset your</h2>
                                    <span className="guest-sign-in">Password</span>
                                </div>
                                <div className="col-12" style={{textAlign: 'left'}}>
                                    <Password
                                        className={this.state.errors?.newPassword !== undefined ? "p-error" : ""}
                                        placeholder="New password"
                                        name="newPassword"
                                        value={this.state.newPassword}
                                        onChange={this.changeHandler}
                                        disabled={this.state.inProcess}
                                    />
                                    {this.state.errors?.newPassword !== undefined ?
                                        <small className="p-messages p-messages-error textBold">{this.state.errors?.newPassword}</small>
                                        : ""
                                    }
                                </div>
                                <div className="col-12" style={{textAlign: 'left'}}>
                                    <InputText
                                        className={this.state.errors?.confirmPassword !== undefined ? "p-error" : ""}
                                        placeholder="Confirm password"
                                        name="confirmPassword"
                                        type="password"
                                        onChange={this.changeHandler}
                                        value={this.state.confirmPassword}
                                        disabled={this.state.inProcess}
                                    />
                                    {this.state.errors?.confirmPassword !== undefined ?
                                        <small className="p-messages p-messages-error textBold">{this.state.errors?.confirmPassword}</small>
                                        : ""
                                    }
                                </div>
                                <div className="col-12 p-md-6 button-pane">
                                    <Button label="Submit"
                                            className={this.state.inProcess ? 'secondary-btn' : "p-button-rounded p-button-primary"}
                                            disabled={this.state.inProcess} onClick={this.submitHandler}
                                    />
                                </div>
                                <div className="col-12 p-md-6 button-pane">
                                    <Button label="Login"
                                            className="p-button-secondary p-button-text link"
                                            onClick={(e) => {
                                                this.props.history.push("/login")
                                            }}>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
