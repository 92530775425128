import axios from 'axios';

export class Sep {
    getConfig = (params) => {
        let access_token = process.env.REACT_APP_TOKEN8;
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }

    postAvanceReforzamiento(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/reforzamiento/avance/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postAvanceExamen(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/examen/alumno/avance/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }



}
