import axios from 'axios';

export class UsuarioService {
    getConfig = (params) => {
        let access_token = localStorage.getItem('access_token');
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }

    getUsuarioLogueo() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/profile/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

}
