import React, {Component} from "react";
import { classNames } from 'primereact/utils';
import {SepMounstrosamenteService} from "../../service/sep_mounstrosamente/SepMounstrosamenteService";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {isEmpty, validateEmail} from "../utils/commonFunctions";
import {Messages} from "primereact/messages";

class ReforzamientosCatalog extends Component {
    emptyReforzamiento = {
        id: null,
        label:"",
        description:"",
        site:"",
        email:"",
        phone:""
    };

    constructor(props) {
        super(props);
        this.state = {
            list_reforzamiento: null,
            reforzamientoDialog: false,
            reforzamiento: this.emptyReforzamiento,
            selectedReforzamiento: null,
            globalFilter: null
        }
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveReforzamiento = this.saveReforzamiento.bind(this);
        this.editReforzamiento = this.editReforzamiento.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);


        this.sep = new SepMounstrosamenteService();
    }

    componentDidMount() {
        this.sep.getListReforzamientos().then(resp => {
            const list_reforzamiento = resp.data;
            this.setState({list_reforzamiento});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading reforzamiento list",
                life: 5000
            })
        });

    }

    openNew() {
        this.setState({
            reforzamiento: this.emptyReforzamiento,
            submitted: false,
            reforzamientoDialog: true
        });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Nuevo reforzamiento" icon="pi pi-plus p-px-2" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Agregar lista de reforzamientos" icon="pi pi-plus" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editReforzamiento(rowData)} />
            </React.Fragment>
        );
    }

    imageBodyTemplate(rowData) {

        return <img src={`https://lms-test.aefcm.gob.mx${rowData.edx_course_image}`}
                    onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                    alt={rowData.image} className="product-image p-shadow-24"/>;
    }


    saveReforzamiento() {
        let state = { submitted: true };
        let list_reforzamiento = [...this.state.list_reforzamiento];
        let reforzamiento = {...this.state.reforzamiento};
        // validate reforzamiento
        const errors = [];
        if (isEmpty(reforzamiento.label)) {
            errors.push({severity: 'error', summary: '', detail: 'Label is mandatory', life: 4000})
        }
        if (isEmpty(reforzamiento.site)) {
            errors.push({severity: 'error', summary: '', detail: 'Site is mandatory', life: 4000})
        }
        if (!isEmpty(reforzamiento.email) && !validateEmail(reforzamiento.email)) {
            errors.push({severity: 'error', summary: '', detail: 'Email format is wrong', life: 4000})
        }
        if(errors.length > 0) {
            this.msgs.show(errors);
            return;
        }

        if (this.state.reforzamiento.id) {
            this.sep.updateReforzamiento(reforzamiento).then(resp => {
                debugger
                state = {
                    ...state,
                    list_reforzamiento,
                    reforzamientoDialog: false,
                    reforzamiento: this.emptyReforzamiento
                };
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Reforzamiento Updated', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error update reforzamiento",
                    life: 5000
                })
            });
        }
        else {
            this.sep.newReforzamiento(reforzamiento).then(resp => {
                state = {
                    ...state,
                    list_reforzamiento,
                    reforzamientoDialog: false,
                    reforzamiento: this.emptyReforzamiento
                };
                list_reforzamiento.push(reforzamiento);
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Reforzamiento Created', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error saving reforzamiento",
                    life: 5000
                })
            });
        }
    }

    editReforzamiento(product) {
        this.setState({
            reforzamiento: { ...product },
            reforzamientoDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            reforzamientoDialog: false
        });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let reforzamiento = {...this.state.reforzamiento};
        reforzamiento[`${name}`] = val;

        this.setState({ reforzamiento });
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-mx-0 p-my-1">Reforzamientos</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Search..."/>
                </span>
            </div>
        );

        const reforzamientoDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveReforzamiento} />
            </React.Fragment>
        );

        const dialogReforzamiento = (
            <Dialog visible={this.state.reforzamientoDialog} style={{ width: '1400px' }} header="Reforzamiento" modal className="p-fluid" footer={reforzamientoDialogFooter} onHide={this.hideDialog}>
                <div className="col-12">
                    <Messages ref={(el) => this.msgs = el} />
                </div>
                <div className="p-field" style={{display: "none"}}>
                    <label htmlFor="id">id</label>
                    <InputText id="id" value={this.state.reforzamiento.id}
                               onChange={(e) => this.onInputChange(e, 'id')}
                               required autoFocus
                               className={classNames({ 'p-invalid': this.state.submitted && !this.state.reforzamiento.label })} />
                    {this.state.submitted && !this.state.reforzamiento.label && <small className="p-error">Label is required.</small>}
                </div>
                <div className="p-grid p-align-center">
                <div className="p-field p-col-3">
                    <label htmlFor="nombre">nombre</label>
                    <InputTextarea id="nombre" value={this.state.reforzamiento.nombre}
                                   onChange={(e) => this.onInputChange(e, 'nombre')} required rows={2} cols={20} />
                </div>

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_label">edx_label</label>
                        <InputText id="edx_label" value={this.state.reforzamiento.edx_label}
                                     onChange={(e) => this.onInputChange(e, 'edx_label')} />
                    </div>

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_id">edx_id</label>
                        <InputText id="edx_id" value={this.state.reforzamiento.edx_id}
                                     onChange={(e) => this.onInputChange(e, 'edx_id')} />
                    </div>

                        <div className="p-field p-col-3">
                            <label htmlFor="edx_name">edx_name</label>
                            <InputText id="edx_name" value={this.state.reforzamiento.edx_name}
                                       onChange={(e) => this.onInputChange(e, 'edx_name')} />
                        </div>


                </div>
                <div className="p-grid p-align-center">


                    <div className="p-field p-col-3">
                        <label htmlFor="edx_org">edx_org</label>
                        <InputText id="edx_org" value={this.state.reforzamiento.edx_org}
                                   onChange={(e) => this.onInputChange(e, 'edx_org')} />
                    </div>


                    <div className="p-field p-col-3">
                        <label htmlFor="edx_start">edx_start</label>
                        <InputText id="edx_start" value={this.state.reforzamiento.edx_start}
                                   onChange={(e) => this.onInputChange(e, 'edx_start')} />
                    </div>


                    <div className="p-field p-col-3">
                        <label htmlFor="edx_start_display">edx_start_display</label>
                        <InputText id="edx_start_display" value={this.state.reforzamiento.edx_start_display}
                                   onChange={(e) => this.onInputChange(e, 'edx_start_display')} />
                    </div>
                    <div className="p-field p-col-3">
                        <label htmlFor="edx_course_image">edx_course_image</label>
                        <InputText id="edx_course_image" value={this.state.reforzamiento.edx_course_image}
                                   onChange={(e) => this.onInputChange(e, 'edx_course_image')} />
                    </div>

                </div>
                <div className="p-grid p-align-center">

                    <div className="p-field p-col-3">
                        <label htmlFor="status">status</label>
                        <InputText id="status" value={this.state.reforzamiento.status}
                                   onChange={(e) => this.onInputChange(e, 'status')} />
                    </div>


                    <div className="p-field p-col-3">
                        <label htmlFor="dcreated">dcreated</label>
                        <InputText id="dcreated" value={this.state.reforzamiento.dcreated}
                                   onChange={(e) => this.onInputChange(e, 'dcreated')} />
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className="grid datatable-crud-demo">
                    <div className="col-12 m-t-20">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    <div className="col-12">
                        <DataTable ref={(el) => this.dt = el} value={this.state.list_reforzamiento} selection={this.state.selectedReforzamiento}
                                   onSelectionChange={(e) => this.setState({ selectedReforzamiento: e.value })}
                                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reforzamientos"
                                   globalFilter={this.state.globalFilter} header={header} responsiveLayout="scroll">
                            <Column expander style={{ width: '3em' }} />
                            <Column body={this.actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                            <Column header="image" body={this.imageBodyTemplate} ></Column>
                            <Column field="id" header="id" style={{display: "none"}}></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="edx_label" header="edx label" ></Column>
                            <Column field="edx_id" header="edx id" ></Column>
                            <Column field="edx_org" header="edx org" ></Column>
                            <Column field="edx_start" header="edx start" ></Column>
                            <Column field="edx_start_display" header="edx start display" ></Column>
                            <Column field="edx_course_image" header="edx course image" ></Column>
                            <Column field="status" header="Status" ></Column>
                            <Column field="dcreated " header="Dcreated" style={{display: "none"}}></Column>
                        </DataTable>
                    </div>
                    {dialogReforzamiento}
                </div>
            </div>
        );
    }
}
export default ReforzamientosCatalog;
