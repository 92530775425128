
import React, {Component} from 'react';
import {Toast} from 'primereact/toast';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import './layout-blue.css';
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {validateEmail} from "../utils/commonFunctions";

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            urlLogo: '',
            email: '',
            inProcess: false,
        }
        this.worxflowServices = new WorxflowServices();
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.submitHandler = this.submitHandler.bind(this);

    }

    componentDidMount() {
        //localStorage.setItem('urlLogo', urlLogo);
        //localStorage.setItem('urlHeader', urlHeader);

    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitHandler(e);
        }
    }

    submitHandler = (e) => {
        e.preventDefault();
        this.setState({inProcess: true});
        const params = {
            email:this.state.email
        }
        const errors = this.validate(params);
        if(Object.keys(errors).length === 0){
            this.worxflowServices.forgotPasswordService(params).then(resp => {
                if(resp["status code"] === 200){
                    this.toast.show({severity: 'success', summary: 'Success email send', detail: resp["message"]});
                    setTimeout(() => {
                        this.props.history.push("/login")
                    }, 5000);
                }
            }).catch(error => {
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.toast.show({severity: 'error', summary: 'Error', detail: errors.email});
            this.setState({inProcess: false});
        }
        console.log("submit forgot password");
    }

    validate = (data) => {
        let errors = {};
        if (!data.email) {
            errors.email = 'Email is mandatory.';
        } else if (data.email && !validateEmail(data.email)) {
            errors.email = 'Email format is not valid.';
        }
        return errors;
    }

    render() {
        return (
            <div className="login-body">
                <Toast ref={(el) => this.toast = el} />
                <div className="col-12 rightContainer">
                    <div className="col-12 rightContainer">
                        <div className="col-12" style={{
                            position: 'relative',
                            height: '10%',
                            alignItems: 'center',
                            color: "white",
                            fontSize: '24px'
                        }}>
                            <h1 style={{
                                position: 'absolute',
                                top: '20%',
                                left: '25%',
                                marginRight: '5%',
                                fontSize: '20px',
                                color: "white"
                            }}>

                            </h1>
                        </div>
                        <div className="col-12" style={{textAlign: 'center'}}>
                            <div className="col-12">
                                <img alt="Logo" src={this.state.urlLogo} className="topbar-logo"/>
                            </div>
                        </div>
                    </div>
                    <div className="card login-panel p-fluid">
                        <div className="login-panel-content">
                            <div className="grid">
                                <div className="col-3" style={{textAlign: 'left'}}>
                                    <img src="assets/layout/images/login/icon-login.svg" alt="avalon-react"/>
                                </div>
                                <div className="col-9 mt-10" style={{textAlign: 'left'}}>
                                    <h2 className="welcome-text">Forgot</h2>
                                    <span className="guest-sign-in">Password</span>
                                </div>
                                <div className="col-12" style={{textAlign: 'left'}}>
                                    <label className="login-label">Email</label>
                                    <div className="login-input">
                                        <InputText placeholder="Email"
                                                   autoComplete="off"
                                                   onKeyPress={this._handleKeyPress}
                                                   value={this.state.email}
                                                   disabled={this.state.inProcess}
                                                   onChange={(e) => this.setState({email: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 md:col-6 button-pane">
                                    <Button label="Submit"
                                            className={this.state.inProcess ? 'secondary-btn' : "p-button-primary"}
                                            disabled={this.state.inProcess} onClick={this.submitHandler}
                                    />
                                </div>
                                <div className="col-12 md:col-6 button-pane">
                                    <Button label="Login"
                                            className="p-button-secondary p-button-text link"
                                            onClick={(e) => {
                                                this.props.history.push("/login")
                                            }}>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ForgotPassword;
