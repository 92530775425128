import React, {Component} from 'react';

import {Redirect} from "react-router-dom";
import './sigup.css'
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";

class SignUp extends Component{
    constructor(){
        super();
        this.state = {
            fistName: '',
            middleName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',
            userName: '',
            redirectLogin: false,
            routerLogin: false,
        }
        this.sigup = this.sigup.bind(this);
        this.validaDatos = this.validaDatos.bind(this);
        this.showMessage = this.showMessage.bind(this);
        this.routerLogin = this.routerLogin.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
    }

    sigup(){
        if(this.validaDatos()){
            let request = {};
            request.email = this.state.email;
            request.firstName = this.state.fistName;
            request.lastName = this.state.lastName;
            request.middleName = this.state.middleName;
            request.password = this.state.password;
            request.userName = this.state.email;
            request.confPassword = this.state.confirmPassword;
            request.typeRegisterID = 1;//Registro normal
            /*
            this.usuarioService.signUp(request).then(resp =>{
                if(resp.statusResponse === 200 ){
                    this.setState({routerLogin: true});
                }else{
                    this.showMessage("error","Error", "Error singup");
                }
            }).catch(error => {
                this.showMessage("error","Error", "Error singup");
            })
            */
        }else{
            this.showMessage("warn","Alert", "Password don't match");
        }

    }

    responseFacebook(response){
        console.log(response);
        let request = {};
        request.email = response.email;
        request.firstName = response.name;
        request.lastName = response.name;
        // request.middleName = this.state.middleName;
        request.password = response.id;
        request.userName = response.email;
        request.confPassword = response.id;
        request.typeRegisterID = 2;//Registro facebook
        request.token = response.accessToken;//Registro facebook
        /*
        this.signupService.signUp(request).then(resp =>{
            if(resp.statusResponse === 200 ){
                this.setState({routerLogin: true});
            }else{
                this.showMessage("error","Error", "Error singup");
            }
        }).catch(error => {
            this.showMessage("error","Error", "Error singup");
        })
        */
    }

    validaDatos(){
        let success = true;
        if(this.state.password !== this.state.confirmPassword){
            success = false;
        }
        return success;
    }

    showMessage(severityMsg, summaryMsg, detailMsg) {
        let msg = {severity: severityMsg, summary: summaryMsg, detail: detailMsg};
        this.toast.show(msg);
    }

    routerLogin(){
        this.setState({routerLogin: true});
    }

    render() {
        if(this.state.redirectLogin) {
            return (<Redirect to={`/login?s=1`}/>);
        }

        if(this.state.routerLogin) {
            return (<Redirect to={`/login`}/>);
        }

        return(
            <div className="ui-g-12" style={{height: '100%'}}>
                <Toast ref={(el) => this.toast = el} />
                <div className="ui-fluid leftContainer ui-g-6" >
                    <div className="ui-g">
                        <div className="ui-g-12">
                            <div className="card card-w-title" style={{padding: '0px'}}>
                                <div className="ui-g form-group">
                                    <div className="ui-g-12">
                                        <img alt="Logo" src="/assets/logo-cv.png" className="topbar-logo" style={{height: '100px'}}/>
                                    </div>
                                    <div className="ui-g-2" style={{color: '#9b9b9b', cursor: 'pointer'}} onClick={this.routerLogin}>
                                        <h2>Login</h2>
                                    </div>
                                    <div className="ui-g-2" style={{color: '#282828'}}>
                                        <h2>Signup</h2>
                                    </div>
                                    <div className="ui-g-12 ui-lg-12">
                                        <label style={{fontSize: '14px'}}>*First Name</label>
                                        <InputText value={this.state.fistName} onChange={(e) => this.setState({fistName: e.target.value})} style={{width:'100%'}}/>
                                    </div>

                                    <div className="ui-g-12 ui-lg-12">
                                        <label style={{fontSize: '14px'}}>*Last Name</label>
                                        <InputText value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})} style={{width:'100%'}}/>
                                    </div>

                                    <div className="ui-g-12 ui-lg-12">
                                        <label style={{fontSize: '14px'}}>*Username(Email)</label>
                                        <InputText value={this.state.email} onChange={(e) => this.setState({email: e.target.value})} style={{width:'100%'}}/>
                                    </div>

                                    {
                                        false && <div className="ui-g-12 ui-lg-12">
                                            <label style={{fontSize: '14px'}}>*Username(Email)</label>
                                            <InputText value={this.state.userName} onChange={(e) => this.setState({userName: e.target.value})} style={{width:'100%'}}/>
                                        </div>
                                    }

                                    <div className="ui-g-12 ui-lg-6">
                                        <label style={{fontSize: '14px'}}>Password</label>
                                        <Password value={this.state.password} feedback={false} onChange={(e) => this.setState({password: e.target.value})} style={{width:'100%'}}/>
                                    </div>

                                    <div className="ui-g-12 ui-lg-6">
                                        <label style={{fontSize: '14px'}}>*Confirm password</label>
                                        <Password value={this.state.confirmPassword} feedback={false} onChange={(e) => this.setState({confirmPassword: e.target.value})} style={{width:'100%'}}/>
                                    </div>

                                    <div className="ui-g-12 ui-lg-4"></div>
                                    <div className="ui-g-12 ui-lg-4">
                                        <center>
                                            <Button label='Sigup' className="p-button-rounded p-button-success" onClick={this.sigup} />
                                        </center>
                                    </div>
                                    <div className="ui-g-12 ui-lg-4"></div>

                                    <div className="ui-g-12 ui-md-12" style={{paddingTop: '10px'}}>
                                        <center>
                                            <div className="ui-g-3 ui-md-3"></div>
                                            <div className="ui-g-3 ui-md-3">
                                                GLogin
                                            </div>
                                            <div className="ui-g-3 ui-md-3">
                                                responseFacebook
                                            </div>
                                            <div className="ui-g-3 ui-md-3"></div>
                                        </center>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ui-g-6 rightContainer">
                    {true &&
                    <div className="ui-g-12" style={{height: '20%', textAlign: 'right'}}>
                        <div className="ui-g-6"></div>
                        <div className="ui-g-6" >
                            <img src="assets/layout/images/signup/credit-card-large.png" alt="card"/>
                        </div>
                    </div>
                    }

                    <div className="ui-g-12" style={{position:'relative', height: '45%', alignItems: 'center', color: "white", fontSize:'24px'}}>
                        <h1 style={{position: 'absolute', top: '20%', left: '25%', marginRight: '5%',fontSize: '20px', color: "white"}}>
                            We are a member based social platform for those who want to take charge of this trend. Based on profound knowledge in investing and technologies, we provide our members the digital tools to improve their long term retirement strategy.
                        </h1>
                    </div>

                    {true &&
                    <div className="ui-g-12" style={{height: '20%'}}>
                        <div className="ui-g-6">
                            <img src="assets/layout/images/signup/wallet-large.png" alt="wallet"/>
                        </div>
                        <div className="ui-g-6"></div>
                    </div>
                    }

                </div>
            </div>
        );
    }
}export default SignUp;
