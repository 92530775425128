import React, {Component} from "react";
import { classNames } from 'primereact/utils';
import {SepMounstrosamenteService} from "../../service/sep_mounstrosamente/SepMounstrosamenteService";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {isEmpty, validateEmail} from "../utils/commonFunctions";
import {Messages} from "primereact/messages";
import { Dropdown } from 'primereact/dropdown';

class EscuelasCatalog extends Component {
    emptyEscuela = {
        id: null,
        label:"",
        description:"",
        site:"",
        email:"",
        phone:""
    };

    constructor(props) {
        super(props);
        this.state = {
            list_escuelas: null,
            escuelaDialog: false,
            newEscuelaDialog: false,
            escuela: this.emptyEscuela,
            selectedEscuela: null,
            globalFilter: null,
            selectedNivel: null,
            selectedTurno: null,
            SelectedAlcaldia: null,
            SelectedDireccionOp: null,
            SelectedDireccionGral: null,
            SelectedDependenciaNormativa: null,
            SelectedNewTurno: null,
            SelectedNewNivel: null,
            SelectedNewAlcaldia: null,
            SelectedNewDireccionOp: null,
            SelectedNewDireccionGral: null,
            SelectedNewDependenciaNormativa: null,
        }
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveEscuela = this.saveEscuela.bind(this);
        this.editEscuela = this.editEscuela.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.onInputNewNivelChange = this.onInputNewNivelChange.bind(this);
        this.onInputNewDirGralChange = this.onInputNewDirGralChange.bind(this);
        this.onInputNewAlcaldiaChange = this.onInputNewAlcaldiaChange.bind(this);
        this.onInputNewDepNormChange = this.onInputNewDepNormChange.bind(this);
        this.onInputNewTurnoChange = this.onInputNewTurnoChange.bind(this);
        this.onInputNewDirOpChange = this.onInputNewDirOpChange.bind(this);
        this.exportCSV = this.exportCSV.bind(this);

        this.sep = new SepMounstrosamenteService();
    }

    componentDidMount() {
        this.sep.getListEscuelas().then(resp => {
            const list_escuelas = resp.data;
            this.setState({list_escuelas});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading escuelas list",
                life: 5000
            })
        });
        this.sep.getCatalogAlumnos().then(resp => {
            const list_turnos = resp.data.turnos;
            const list_niveles = resp.data.niveles;
            const list_alcaldias = resp.data.alcaldias;
            const list_direccionOperativas = resp.data.direccion_operativas;
            const list_direccionGenerales = resp.data.direcciones_grales;
            const list_depNorvativas = resp.data.dependencias_normativas;
            this.setState({
                list_turnos,
                list_niveles,
                list_alcaldias,
                list_direccionOperativas,
                list_direccionGenerales,
                list_depNorvativas});
            debugger
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading escuelas list",
                life: 5000
            })
        });

    }

    openNew() {
        this.setState({
            escuela: this.emptyEscuela,
            submitted: false,
            escuelaDialog: true,
            newEscuelaDialog: true,
        });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Nueva Escuela" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editEscuela(rowData)} />
            </React.Fragment>
        );
    }

    saveEscuela() {
        let state = { submitted: true };
        let list_escuelas = [...this.state.list_escuelas];
        let escuela = {...this.state.escuela};
        // validate escuela
        const errors = [];
        if (isEmpty(escuela.label)) {
            errors.push({severity: 'error', summary: '', detail: 'Label is mandatory', life: 4000})
        }
        if (isEmpty(escuela.site)) {
            errors.push({severity: 'error', summary: '', detail: 'Site is mandatory', life: 4000})
        }
        if (!isEmpty(escuela.email) && !validateEmail(escuela.email)) {
            errors.push({severity: 'error', summary: '', detail: 'Email format is wrong', life: 4000})
        }
        if(errors.length > 0) {
            this.msgs.show(errors);
            return;
        }

        if (this.state.escuela.id) {
            this.sep.updateEscuela(escuela).then(resp => {
                debugger
                state = {
                    ...state,
                    list_escuelas,
                    escuelaDialog: false,
                    newEscuelaDialog: false,
                    escuela: this.emptyEscuela
                };
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Escuela Updated', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error update escuela",
                    life: 5000
                })
            });
        }
        else {
            this.sep.newEscuela(escuela).then(resp => {
                state = {
                    ...state,
                    list_escuelas,
                    escuelaDialog: false,
                    newEscuelaDialog: false,
                    escuela: this.emptyEscuela
                };
                list_escuelas.push(escuela);
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Escuela Created', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error saving escuela",
                    life: 5000
                })
            });
        }
    }

    editEscuela(escuela) {
        debugger
        this.state.list_turnos.forEach((turno, index) => {
            if (turno.id === escuela.turno) {
                return this.setState({selectedTurno: turno});
            }
        });
        this.state.list_niveles.forEach((nivel) => {
            if (nivel.id === escuela.nivel) {
                return this.setState({selectedNivel: nivel});
            }
        });
        this.state.list_alcaldias.forEach((alcaldia) => {
            if (alcaldia.id === escuela.alcaldia) {
                return this.setState({selectedAlcaldia: alcaldia});
            }
        });
        this.state.list_depNorvativas.forEach((normativa) => {
            if (normativa.id === escuela.normativa) {
                return this.setState({selectedDependenciaNormativa: normativa});
            }
        });
        this.state.list_direccionOperativas.forEach((operativa) => {
            if (operativa.id === escuela.operativa) {
                return this.setState({selectedDireccionOp: operativa});
            }
        });
        this.state.list_direccionGenerales.forEach((generales) => {
            if (generales.id === escuela.general) {
                return this.setState({selectedDireccionGral: generales});
            }
        });

        this.setState({
            escuela: { ...escuela },
            escuelaDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            escuelaDialog: false,
            newEscuelaDialog: false,
        });
    }

    onInputChange(e, name) {
        debugger
        const val = (e.target && e.target.value) || '';
        let escuela = {...this.state.escuela};
        escuela[`${name}`] = val;
        this.setState({ escuela });
    }

    onInputNewTurnoChange(e) {
        debugger
        this.setState({selectedNewTurno: e.value});
        this.setState({selectedTurno: e.value});
    }
    onInputNewNivelChange(e) {
        debugger
        this.setState({selectedNewNivel: e.value});
    }

    onInputNewAlcaldiaChange(e) {
        debugger
        this.setState({selectedNewAlcaldia: e.value});
    }
    onInputNewDirGralChange(e) {
        this.setState({selectedNewDireccionGral: e.value});
    }
    onInputNewDepNormChange(e) {
        this.setState({selectedNewDependenciaNormativa: e.value});
    }
    onInputNewDirOpChange(e) {
        this.setState({selectedNewDireccionOp: e.value});
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-mx-0 p-my-1">Escuelas</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Search..."/>
                </span>
            </div>
        );

        const escuelaDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveEscuela} />
            </React.Fragment>
        );

        const dialogEscuela = (
            <Dialog visible={this.state.escuelaDialog} style={{ width: '1400px' }} header="Escuela" modal className="p-fluid" footer={escuelaDialogFooter} onHide={this.hideDialog}>
                <div className="col-12">
                    <Messages ref={(el) => this.msgs = el} />
                </div>
                <div className="p-grid p-align-center">

                    <div className="p-field p-col-3">
                        <label htmlFor="nombre">Nombre</label>
                        <InputTextarea id="nombre" value={this.state.escuela.nombre}
                                       onChange={(e) => this.onInputChange(e, 'nombre')} required rows={2} cols={20} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="clave_cct">clave_cct</label>
                        <InputText id="clave_cct" value={this.state.escuela.clave_cct}
                                   onChange={(e) => this.onInputChange(e, 'edx_label')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="nivel">nivel</label>
                        <Dropdown options={this.state.list_niveles} value={this.state.selectedNivel}  optionLabel="nombre" placeholder={"Nivel"}
                                  onChange={this.onInputNewNivelChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="turno">turno</label>
                        <Dropdown options={this.state.list_turnos} value={this.state.selectedTurno}  optionLabel="nombre" placeholder={"Turno"}
                                  onChange={this.onInputNewTurnoChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="alcaldia">Alcaldia</label>
                        <Dropdown options={this.state.list_alcaldias} value={this.state.selectedNewAlcaldia}  optionLabel="nombre" placeholder={"Alcaldia"}
                                  onChange={this.onInputNewAlcaldiaChange}/>
                    </div>
                </div>
                <div className="p-grid p-align-center">
                    <div className="p-field p-col-3">
                        <label htmlFor="domicilio">domicilio</label>
                        <InputTextarea id="domicilio" value={this.state.escuela.domicilio}
                                       onChange={(e) => this.onInputChange(e, 'edx_name')}  required rows={2} cols={20} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="colonia">colonia</label>
                        <InputText id="colonia" value={this.state.escuela.colonia}
                                   onChange={(e) => this.onInputChange(e, 'edx_course_image')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="direccion_operativa">direccion_operativa</label>
                        <Dropdown options={this.state.list_direccionOperativas} value={this.state.selectedNewDireccionOp}  optionLabel="nombre" placeholder={"direccion_operativa"}
                                  onChange={this.onInputNewDirOpChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="direccion_general">direccion_general</label>
                        <Dropdown options={this.state.list_direccionGenerales} value={this.state.selectedNewDireccionGral}  optionLabel="nombre" placeholder={"direccion_general"}
                                  onChange={this.onInputNewDirGralChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="dependencia_normativa">dependencia_normativa</label>
                        <Dropdown options={this.state.list_depNorvativas} value={this.state.selectedNewDependenciaNormativa}  optionLabel="nombre" placeholder={"dependencia_normativa"}
                                  onChange={this.onInputNewDepNormChange}/>
                    </div>
                </div>
                <div className="p-grid p-align-center">
                    <div className="p-field p-col-3">
                        <label htmlFor="servicio_regional">servicio_regional</label>
                        <InputText id="servicio_regional" value={this.state.escuela.servicio_regional}
                                   onChange={(e) => this.onInputChange(e, 'edx_start')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="sostenimiento_f912">sostenimiento_f912</label>
                        <InputText id="sostenimiento_f912" value={this.state.escuela.sostenimiento_f912}
                                   onChange={(e) => this.onInputChange(e, 'status')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="sostenimiento_aefcm">sostenimiento_aefcm</label>
                        <InputText id="sostenimiento_aefcm" value={this.state.escuela.sostenimiento_aefcm}
                                   onChange={(e) => this.onInputChange(e, 'dcreated')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="programa">programa</label>
                        <InputText id="programa" value={this.state.escuela.programa}
                                   onChange={(e) => this.onInputChange(e, 'dcreated')} />
                    </div>
                </div>
            </Dialog>
        );

        const dialogNewEscuela = (
            <Dialog visible={this.state.newEscuelaDialog} style={{ width: '1400px' }} header="Nueva Escuela" modal className="p-fluid" footer={escuelaDialogFooter} onHide={this.hideDialog}>
                <div className="col-12">
                    <Messages ref={(el) => this.msgs = el} />
                </div>

                <div className="p-grid p-align-center">

                    <div className="p-field p-col-3">
                        <label htmlFor="nombre">Nombre</label>
                        <InputTextarea id="nombre" value={this.state.escuela.nombre}
                                       onChange={(e) => this.onInputChange(e, 'nombre')} required rows={2} cols={20} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="clave_cct">clave_cct</label>
                        <InputText id="clave_cct" value={this.state.escuela.clave_cct}
                                   onChange={(e) => this.onInputChange(e, 'edx_label')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="nivel">nivel</label>
                        <Dropdown options={this.state.list_niveles} value={this.state.selectedNewNivel}  optionLabel="nombre" placeholder={"Nivel"}
                                  onChange={this.onInputNewNivelChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="turno">turno</label>
                        <Dropdown options={this.state.list_turnos} value={this.state.selectedNewTurno}  optionLabel="nombre" placeholder={"Turno"}
                                  onChange={this.onInputNewTurnoChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="alcaldia">Alcaldia</label>
                        <Dropdown options={this.state.list_alcaldias} value={this.state.selectedNewAlcaldia}  optionLabel="nombre" placeholder={"Alcaldia"}
                                  onChange={this.onInputNewAlcaldiaChange}/>
                    </div>
                </div>
                <div className="p-grid p-align-center">
                    <div className="p-field p-col-3">
                        <label htmlFor="domicilio">domicilio</label>
                        <InputTextarea id="domicilio" value={this.state.escuela.domicilio}
                                   onChange={(e) => this.onInputChange(e, 'edx_name')}  required rows={2} cols={20} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="colonia">colonia</label>
                        <InputText id="colonia" value={this.state.escuela.colonia}
                                   onChange={(e) => this.onInputChange(e, 'edx_course_image')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="direccion_operativa">direccion_operativa</label>
                        <Dropdown options={this.state.list_direccionOperativas} value={this.state.selectedNewDireccionOp}  optionLabel="nombre" placeholder={"direccion_operativa"}
                                  onChange={this.onInputNewDirOpChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="direccion_general">direccion_general</label>
                        <Dropdown options={this.state.list_direccionGenerales} value={this.state.selectedNewDireccionGral}  optionLabel="nombre" placeholder={"direccion_general"}
                                  onChange={this.onInputNewDirGralChange}/>
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="dependencia_normativa">dependencia_normativa</label>
                        <Dropdown options={this.state.list_depNorvativas} value={this.state.selectedNewDependenciaNormativa}  optionLabel="nombre" placeholder={"dependencia_normativa"}
                                  onChange={this.onInputNewDepNormChange}/>
                    </div>
                </div>
                <div className="p-grid p-align-center">
                    <div className="p-field p-col-3">
                        <label htmlFor="servicio_regional">servicio_regional</label>
                        <InputText id="servicio_regional" value={this.state.escuela.servicio_regional}
                                   onChange={(e) => this.onInputChange(e, 'edx_start')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="sostenimiento_f912">sostenimiento_f912</label>
                        <InputText id="sostenimiento_f912" value={this.state.escuela.sostenimiento_f912}
                                   onChange={(e) => this.onInputChange(e, 'status')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="sostenimiento_aefcm">sostenimiento_aefcm</label>
                        <InputText id="sostenimiento_aefcm" value={this.state.escuela.sostenimiento_aefcm}
                                   onChange={(e) => this.onInputChange(e, 'dcreated')} />
                    </div>
                    <div className="p-field p-col-2">
                        <label htmlFor="programa">programa</label>
                        <InputText id="programa" value={this.state.escuela.programa}
                                   onChange={(e) => this.onInputChange(e, 'dcreated')} />
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className="grid datatable-crud-demo">
                    <div className="col-12 m-t-20">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    <div className="col-12">
                        <DataTable ref={(el) => this.dt = el} value={this.state.list_escuelas} selection={this.state.selectedEscuela}
                                   onSelectionChange={(e) => this.setState({ selectedEscuela: e.value })}
                                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Escuelas"
                                   globalFilter={this.state.globalFilter} header={header} responsiveLayout="scroll">
                            <Column body={this.actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                            <Column field="id" header="id" style={{display: "none"}}></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="clave_cct" header="clave_cct" sortable></Column>
                            <Column field="nivel" header="Nivel" sortable></Column>
                            <Column field="turno" header="Turno" sortable></Column>
                            <Column field="alcaldia" header="Alcaldia" sortable></Column>
                            <Column field="servicio_regional" header="Servicio Regional" sortable></Column>
                            <Column field="direccion_operativa" header="Dirección Operativa" sortable></Column>
                            <Column field="domicilio" header="Domicilio" sortable></Column>
                            <Column field="colonia" header="Colonia" sortable></Column>
                            <Column field="dependencia_normativa" header="Dependencia Normativa" sortable></Column>
                            <Column field="sostenimiento_f912" header="Sostenimiento f912" ></Column>
                            <Column field="direccion_general" header="Direccion General" sortable></Column>
                            <Column field="sostenimiento_aefcm" header="Sostenimiento aefcm" ></Column>
                            <Column field="programa" header="Programa" sortable></Column>
                            <Column field="status" header="Status" sortable></Column>
                            <Column field="dcreated" header="Dcreated" style={{display: "none"}}></Column>
                        </DataTable>
                    </div>
                    {dialogEscuela}
                    {dialogNewEscuela}
                </div>
            </div>
        );
    }
}
export default EscuelasCatalog;
