import axios from 'axios';

export class SepMounstrosamenteService {
    getConfig = (params) => {
        let access_token = localStorage.getItem('access_token');
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }



    updateEcommerce(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/alert/recipient/${params.id}/update/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }




    getListReforzamientos() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/reforzamiento/all/`).then(
            resp => {
                return resp.data;
            });
    }

    getListEscuelas() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/escuela/list/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }


    getListExamenes() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/examen/all/`).then(
            resp => {
                return resp.data;
            });
    }
    getCatalogAlumnos() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/catalogos/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    getExamenesCatalog(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/catalogo/examenes/${params.email}/`,this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    getReforzamientosCatalog(params) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/catalogo/reforzamiento/${params.email}/`, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }

    newAlumno(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/new/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postListAlumnos(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/escuela/filter/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postUnrollRefo(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/reforzamiento/unroll/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postUnrollExam(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/examen/unroll/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postBajaLogica(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/baja/logica/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postUnEnrollsReforzamientos(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/reforzamiento/enroll/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }
    postUnEnrollsExamenes(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/admin/alumnos/examen/enroll/`,params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }




}
