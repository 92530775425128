import React, {Component} from 'react';
import {Route, withRouter} from 'react-router-dom';
import App from "./App";
import Access from "./pages/Access";
import ConfirmForgotPassword from "./components/login/ConfirmForgotPassword";
import ConfirmTrackForm from "./components/form/ConfirmTrackForm";
import Error from "./pages/Error";
import ForgotPassword from "./components/login/ForgotPassword";
import Login from "./components/login";
import NotFound from "./pages/NotFound";
import {Wizard} from "./pages/Wizard";
import SignUp from "./components/signup";

class AppWrapper extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        if (this.props.location.pathname.includes("/forgot/")) {
            return <Route path="/forgot/:idUser/:token/" component={ConfirmForgotPassword}/>
        } else if (this.props.location.pathname.includes("/form/")) {
            return <Route path="/form/:user_id/:track_id/:token/" component={ConfirmTrackForm}/>
        } else {
            switch (this.props.location.pathname) {
                case "/login":
                    return <Route path="/login" component={Login}/>
                case "/signup":
                    return <Route path="/signup" component={SignUp}/>
                case "/error":
                    return <Route path="/error" component={Error}/>
                case "/notfound":
                    return <Route path="/notfound" component={NotFound}/>
                case "/access":
                    return <Route path="/access" component={Access}/>
                case "/wizard":
                    return <Route path="/wizard" component={Wizard}/>
                case "/forgot-password":
                    return <Route path="/forgot-password" component={ForgotPassword}/>
                default:
                    return <App/>;
            }
        }
    }
}

export default withRouter(AppWrapper);
