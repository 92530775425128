import axios from 'axios';

export class WorxflowServices {
    getConfig = (params) => {
        let access_token = localStorage.getItem('access_token');
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }

    updateProfile(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/admin/profile/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    changePassword(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/changePassword/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    forgotPasswordService(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/forgotPassword/`,
            params).then(resp => {
            return resp.data;
        });
    }

    resetPasswordService(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/resetPassword/`,
            params).then(resp => {
            return resp.data;
        });
    }

    getTrackstore(user_id, track_id, token) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/worxflow/trackstore/find/${user_id}/${track_id}/${token}/`).then(resp => {
            return resp.data;
        });
    }

    sendCheckTrackstore(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/worxflow/trackstore/check/`, params).then(resp => {
            return resp.data;
        });
    }

    callRecipientList() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/alert/recipient/list/`, this.getConfig()).then(resp => {
            return resp;
        });
    }

    addRecipientList(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/alert/recipient/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    updateRecipientList(id, params) {
        return axios.put(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/alert/recipient/${id}/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    deleteRecipientList(id) {
        return axios.delete(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/alert/recipient/${id}/delete/`, this.getConfig()).then(resp => {
            return resp;
        });
    }

    sendSMSRecipient(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/alert/recipient/send/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

}
