import React, {Component} from "react";
import { classNames } from 'primereact/utils';
import {SepMounstrosamenteService} from "../../service/sep_mounstrosamente/SepMounstrosamenteService";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {isEmpty, validateEmail} from "../utils/commonFunctions";
import {Messages} from "primereact/messages";

class ExamenCatalog extends Component {
    emptyExamen = {
        id: null,
        label:"",
        description:"",
        site:"",
        email:"",
        phone:""
    };

    constructor(props) {
        super(props);
        this.state = {
            list_examen: null,
            examenDialog: false,
            examen: this.emptyExamen,
            selectedExamen: null,
            globalFilter: null
        }
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.imageBodyTemplate = this.imageBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveExamen = this.saveExamen.bind(this);
        this.editExamen = this.editExamen.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);

        this.sep = new SepMounstrosamenteService();
    }

    componentDidMount() {
        this.sep.getListExamenes().then(resp => {
            const list_examen = resp.data;
            this.setState({list_examen});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading examen list",
                life: 5000
            })
        });

    }

    openNew() {
        this.setState({
            examen: this.emptyExamen,
            submitted: false,
            examenDialog: true
        });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Nuevo Exámen" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Agergar lista de exámenes" icon="pi pi-plus" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editExamen(rowData)} />
            </React.Fragment>
        );
    }
    imageBodyTemplate(rowData) {

        return <img src={`https://lms-test.aefcm.gob.mx${rowData.edx_course_image}`}
                    onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                    alt={rowData.image} className="product-image"/>;
    }

    saveExamen() {
        let state = { submitted: true };
        let list_examen = [...this.state.list_examen];
        let examen = {...this.state.examen};
        // validate ecommerce
        const errors = [];
        if (isEmpty(examen.label)) {
            errors.push({severity: 'error', summary: '', detail: 'Label is mandatory', life: 4000})
        }
        if (isEmpty(examen.site)) {
            errors.push({severity: 'error', summary: '', detail: 'Site is mandatory', life: 4000})
        }
        if (!isEmpty(examen.email) && !validateEmail(examen.email)) {
            errors.push({severity: 'error', summary: '', detail: 'Email format is wrong', life: 4000})
        }
        if(errors.length > 0) {
            this.msgs.show(errors);
            return;
        }

        if (this.state.examen.id) {
            this.sep.updateExamen(examen).then(resp => {
                debugger
                state = {
                    ...state,
                    list_examen,
                    examenDialog: false,
                    examen: this.emptyExamen
                };
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Examen Updated', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error update examen",
                    life: 5000
                })
            });
        }
        else {
            this.sep.newExamen(examen).then(resp => {
                state = {
                    ...state,
                    list_examen,
                    examenDialog: false,
                    examen: this.emptyExamen
                };
                list_examen.push(examen);
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Examen Created', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error saving examen",
                    life: 5000
                })
            });
        }
    }

    editExamen(product) {
        this.setState({
            examen: { ...product },
            examenDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            examenDialog: false
        });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let examen = {...this.state.examen};
        examen[`${name}`] = val;

        this.setState({ examen });
    }

    rowExpansionTemplate() {
        return (
            <div className="orders-subtable">
                <h5>Alumnos Asignados</h5>
                <DataTable  responsiveLayout="scroll">
                    <Column field="id" header="Id" sortable></Column>
                </DataTable>
            </div>
        );
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-mx-0 p-my-1">Exámenes</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Search..."/>
                </span>
            </div>
        );

        const examenDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveExamen} />
            </React.Fragment>
        );

        const dialogExamen = (
            <Dialog visible={this.state.examenDialog} style={{ width: '1300px' }} header="Exámen" modal className="p-fluid" footer={examenDialogFooter} onHide={this.hideDialog}>
                <div className="col-12">
                    <Messages ref={(el) => this.msgs = el} />
                </div>
                <div className="p-field" style={{display: "none"}}>
                    <label htmlFor="id">id</label>
                    <InputText id="id" value={this.state.examen.id}
                               onChange={(e) => this.onInputChange(e, 'label')}
                               required autoFocus
                               className={classNames({ 'p-invalid': this.state.submitted && !this.state.examen.label })} />
                    {this.state.submitted && !this.state.examen.label && <small className="p-error">Label is required.</small>}
                </div>

                <div className="p-grid p-align-center">

                <div className="p-field p-col-3">
                    <label htmlFor="nombre">nombre</label>
                    <InputTextarea id="nombre" value={this.state.examen.nombre}
                                   onChange={(e) => this.onInputChange(e, 'nombre')} required rows={2} cols={20} />
                </div>

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_label">edx_label</label>
                        <InputText id="edx_label" value={this.state.examen.edx_label}
                                     onChange={(e) => this.onInputChange(e, 'edx_label')} />
                    </div>

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_id">edx_id</label>
                        <InputText id="edx_id" value={this.state.examen.edx_id}
                                     onChange={(e) => this.onInputChange(e, 'edx_id')} />
                    </div>

                    <div className="p-field p-col-3">
                        <label htmlFor="dcreated">dcreated</label>
                        <InputText id="dcreated" value={this.state.examen.dcreated}
                                   onChange={(e) => this.onInputChange(e, 'dcreated')} />
                    </div>


                </div>
                <div className="p-grid p-align-center">

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_name">edx_name</label>
                        <InputText id="edx_name" value={this.state.examen.edx_name}
                                   onChange={(e) => this.onInputChange(e, 'edx_name')} />
                </div>

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_org">edx_org</label>
                        <InputText id="edx_org" value={this.state.examen.edx_org}
                                   onChange={(e) => this.onInputChange(e, 'edx_org')} />
                    </div>


                    <div className="p-field p-col-3">
                        <label htmlFor="edx_start">edx_start</label>
                        <InputText id="edx_start" value={this.state.examen.edx_start}
                                   onChange={(e) => this.onInputChange(e, 'edx_start')} />
                    </div>
                    <div className="p-field p-col-3">
                        <label htmlFor="status">status</label>
                        <InputText id="status" value={this.state.examen.status}
                                   onChange={(e) => this.onInputChange(e, 'status')} />
                    </div>
                </div>

                <div className="p-grid p-align-center">

                    <div className="p-field p-col-3">
                        <label htmlFor="edx_start_display">edx_start_display</label>
                        <InputText id="edx_start_display" value={this.state.examen.edx_start_display}
                                   onChange={(e) => this.onInputChange(e, 'edx_start_display')} />
                    </div>


                    <div className="p-field p-col-6">
                        <label htmlFor="edx_course_image">edx_course_image</label>
                        <InputText id="edx_course_image" value={this.state.examen.edx_course_image}
                                   onChange={(e) => this.onInputChange(e, 'edx_course_image')} />
                    </div>




                </div>

            </Dialog>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className="grid datatable-crud-demo">
                    <div className="col-12 m-t-20">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    <div className="col-12">
                        <DataTable ref={(el) => this.dt = el} value={this.state.list_examen} selection={this.state.selectedExamen}
                                   onSelectionChange={(e) => this.setState({ selectedExamen: e.value })}
                                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} exámenes"
                                   globalFilter={this.state.globalFilter} header={header} responsiveLayout="scroll"
                                   rowExpansionTemplate={this.rowExpansionTemplate}>
                            <Column expander style={{ width: '3em' }} />
                            <Column body={this.actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                            <Column  body={this.imageBodyTemplate}></Column>
                            <Column field="id" header="id" style={{display: "none"}}></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="edx_label" header="edx label" ></Column>
                            <Column field="edx_id" header="edx id" ></Column>
                            <Column field="edx_name" header="edx name" ></Column>
                            <Column field="edx_org" header="edx org" ></Column>
                            <Column field="edx_start" header="edx start" ></Column>
                            <Column field="edx_start_display" header="edx start display" ></Column>
                            <Column field="edx_course_image" header="edx course image" ></Column>
                            <Column field="status" header="Status" ></Column>
                            <Column field="dcreated" header="Dcreated" style={{display: "none"}}></Column>
                        </DataTable>
                    </div>
                    {dialogExamen}
                </div>
            </div>
        );
    }
}
export default ExamenCatalog;
