import React, {Component} from 'react';
import {Card} from "primereact/card";
import {TabMenu} from "primereact/tabmenu";
import ExamenCatalog from "./ExamenCatalog";

class ListExamenes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
        }
        this.items = [
            {label: 'Exámenes', icon: 'pi pi-fw pi-file'},
        ];
    }

    render() {
        return (
            <div className="pageWrapper">
                <Card>
                    <TabMenu model={this.items} activeIndex={this.state.activeIndex}
                             onTabChange={(e) => this.setState({activeIndex: e.index})}/>
                    {this.state.activeIndex === 0 && <ExamenCatalog/>}
                </Card>
            </div>
        );
    }

}

export default ListExamenes;
