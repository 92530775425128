import axios from 'axios';

export class AppService {

    getConfig = (params) => {
        let appService_access_token = 'Jv5FDLX7HD8vCKTIk6mDlxklfaseqm';
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${appService_access_token}`,
            }
        };
    }

    postExamAndReinforcement(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_USERS}/api/v1/examen/alumno/edx/list/`, params, this.getConfig()).then(
            resp => {
                return resp.data;
            });
    }



}