import React, {Component} from "react";
import { classNames } from 'primereact/utils';
import {SepMounstrosamenteService} from "../../service/sep_mounstrosamente/SepMounstrosamenteService";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";
import {isEmpty, validateEmail} from "../utils/commonFunctions";
import {Messages} from "primereact/messages";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {PickList} from "primereact/picklist";
import { Chip } from 'primereact/chip';


class NotificacionesCatalog extends Component {
    emptyNotificacion = {
        id: null,
        nombre:"",
        para:"",
        descripcion:"",
        fecha:"",
        status_bool:""
    };

    constructor(props) {
        super(props);
        this.state = {
            list_notificaciones: [{
                "id": 1,
                "nombre": "Notificacion 1",
                "para": "Monica," +
                    "omar",
                "descripcion": "Descripcion de notificacion",
                "fecha": "11/22/2022 18:55:26",
                "status_bool": true
            },
                {
                    "id": 2,
                    "nombre": "Notificacion 2",
                    "para": "Omar",
                    "descripcion": "Descripcion de notificacion",
                    "fecha": "11/22/2022 18:55:26",
                    "status_bool": true
                },
                {
                    "id": 3,
                    "nombre": "Notificacion 3",
                    "para": "Charli",
                    "descripcion": "Descripcion de notificacion",
                    "fecha": "11/22/2022 18:55:26",
                    "status_bool": true
                }],
            notificacion: this.emptyNotificacion,
            list_escuelas: null,
            escuelaDialog: false,
            displaynewNotification: false,
            escuela: this.emptyEscuela,
            selectedEscuela: null,
            globalFilter: null,
            list_alumnos: [],
        }
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);

        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.saveEscuela = this.saveEscuela.bind(this);
        this.editEscuela = this.editEscuela.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.onHide = this.onHide.bind(this);
        this.catalogosAlumnos = this.catalogosAlumnos.bind(this);

        this.sep = new SepMounstrosamenteService();
    }
    componentDidMount() {
        this.catalogosAlumnos();
        this.sep.getListEscuelas().then(resp => {
            const list_escuelas = resp.data;
            this.setState({list_escuelas});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading escuelas list",
                life: 5000
            })
        });
    }

    catalogosAlumnos() {
        debugger
        this.sep.postListAlumnos({"escuela_id": null}).then(resp => {
            const list_alumnos = resp.data;
            this.setState({list_alumnos});
            console.log("Alumnos")
            console.log(list_alumnos)
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading alumnos list",
                life: 5000
            })
        });
    }
    openNew() {
        this.setState({
            escuela: this.emptyEscuela,
            submitted: false,
            displaynewNotification: true
        });
    }

    leftToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Nueva Notificación" icon="pi pi-plus" className="p-button-success p-mr-2" onClick={this.openNew} />
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }

    exportCSV() {
        this.dt.exportCSV();
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2" onClick={() => this.editEscuela(rowData)} />
            </React.Fragment>
        );
    }
    actionBodyTemplateChip(rowData) {
        return (
            <React.Fragment>
                        <Chip label="Usuario" className="p-mr-2 p-mb-2"/>
                        <Chip label="Usuario2" className="p-mr-2 p-mb-2"/>
                        <Chip label="Usuario3" className="p-mr-2 p-mb-2"/>
                        <Chip label="Usuario4" className="p-mr-2 p-mb-2"/>
            </React.Fragment>
        );
    }



    saveEscuela() {
        let state = { submitted: true };
        let list_escuelas = [...this.state.list_escuelas];
        let escuela = {...this.state.escuela};
        // validate escuela
        const errors = [];
        if (isEmpty(escuela.label)) {
            errors.push({severity: 'error', summary: '', detail: 'Label is mandatory', life: 4000})
        }
        if (isEmpty(escuela.site)) {
            errors.push({severity: 'error', summary: '', detail: 'Site is mandatory', life: 4000})
        }
        if (!isEmpty(escuela.email) && !validateEmail(escuela.email)) {
            errors.push({severity: 'error', summary: '', detail: 'Email format is wrong', life: 4000})
        }
        if(errors.length > 0) {
            this.msgs.show(errors);
            return;
        }

        if (this.state.escuela.id) {
            this.sep.updateEscuela(escuela).then(resp => {
                debugger
                state = {
                    ...state,
                    list_escuelas,
                    escuelaDialog: false,
                    escuela: this.emptyEscuela
                };
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Escuela Updated', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error update escuela",
                    life: 5000
                })
            });
        }
        else {
            this.sep.newEscuela(escuela).then(resp => {
                state = {
                    ...state,
                    list_escuelas,
                    escuelaDialog: false,
                    escuela: this.emptyEscuela
                };
                list_escuelas.push(escuela);
                this.setState(state);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Escuela Created', life: 5000 });
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error saving escuela",
                    life: 5000
                })
            });
        }
    }

    editEscuela(product) {
        this.setState({
            escuela: { ...product },
            escuelaDialog: true
        });
    }

    hideDialog() {
        this.setState({
            submitted: false,
            displaynewNotification: false,
            escuelaDialog: false
        });
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let notificacion = {...this.state.notificacion};
        notificacion[`${name}`] = val;

        this.setState({ notificacion });
    }

    render() {
        const header = (
            <div className="table-header">
                <h5 className="p-mx-0 p-my-1">Notificaciones</h5>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Search..."/>
                </span>
            </div>
        );

        const escuelaDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.hideDialog} />
            </React.Fragment>
        );

        const itemTemplateExamen = (item) => {

            return (
                <div className="product-item">
                    <div className="product-list-detail">
                        <i className="pi pi-user product-category-icon"></i>
                        <span className="product-category">{item.nombre}</span>
                    </div>
                </div>
            );
        }

        const newNotification = (
            <Dialog header="Nueva Notificación" visible={this.state.displaynewNotification} style={{width: '850px'}}
                    footer={escuelaDialogFooter} modal className="p-fluid"
                    onHide={() => this.onHide('displaynewNotification')}>
                <div className="p-col">
                    <Messages ref={(el) => this.msgs = el}/>
                </div>

                <div className="p-field">
                    <label htmlFor="nombre">Nombre</label>
                    <InputText id="nombre" value={this.state.notificacion.nombre}
                               onChange={(e) => this.onInputChange(e, 'nombre')}/>
                </div>

                <div className="p-field">

                    <label htmlFor="descripción">Descripción</label>
                    <InputTextarea id="descripción"  value={this.state.notificacion.descripcion}
                               onChange={(e) => this.onInputChange(e, 'descripcion')}/>
                </div>
                <div className="p-field">
                    <label htmlFor="fecha">Fecha</label>
                    <Calendar id="fecha"
                              inputClassName="pd-11"
                              todayButtonClassName="pd-11"
                              placeholder="fecha"
                              dateFormat="yy-mm-dd"
                              name="fecha"
                              value
                              onChange={(e) => this.onInputChange(e, 'email')}
                              showTime showSeconds showIcon monthNavigator yearNavigator yearRange="2023:2050"/>
                </div>
                <div className="p-field">
                    <div className="card">
                        <PickList source={this.state.list_alumnos} target={this.state.list_examenesCatalog}
                                  sourceHeader="Disponibles:" targetHeader="Para:" itemTemplate={itemTemplateExamen}
                                  sourceStyle={{ height: '345px' }} targetStyle={{ height: '445px' }}
                                  onChange={(e) => this.onInputChange(e, 'email')}  filterBy="name"  ></PickList>
                    </div>
                </div>
            </Dialog>
        );

        const dialogNotificacion = (
            <Dialog visible={this.state.escuelaDialog} style={{ width: '900px' }} header="Notificación" modal className="p-fluid" footer={escuelaDialogFooter} onHide={this.hideDialog}>
                <div className="col-12">
                    <Messages ref={(el) => this.msgs = el} />
                </div>
                <div className="p-grid">
                    <div className="p-col-6">
                    <label htmlFor="descripcion">Descripción</label>
                    <InputText id="descripcion" value={"Descripción de notificación"}
                                   onChange={(e) => this.onInputChange(e, 'descripcion')} required rows={2} cols={20} />
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="fecha">Fecha</label>
                        <InputText id="fecha" value={"11/22/2022 18:55:26"}
                                     onChange={(e) => this.onInputChange(e, 'fecha')} />
                    </div>
                    <div className="p-col-3">
                        <label htmlFor="status">status</label>
                        <InputText id="status" value={"1"}
                                   onChange={(e) => this.onInputChange(e, 'status')} />
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div>
                <Toast ref={(el) => this.toast = el} />
                <div className="grid datatable-crud-demo">
                    <div className="col-12 m-t-20">
                        <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                    </div>
                    <div className="col-12">
                        <DataTable ref={(el) => this.dt = el} value={this.state.list_notificaciones} selection={this.state.selectedEscuela}
                                   onSelectionChange={(e) => this.setState({ selectedEscuela: e.value })}
                                   dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Notificaciones"
                                   globalFilter={this.state.globalFilter} header={header} responsiveLayout="scroll">
                            <Column body={this.actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                            <Column field="id" header="id" sortable></Column>
                            <Column field="nombre" header="Nombre" sortable></Column>
                            <Column field="fecha" header="Fecha" sortable></Column>
                            <Column field="para" header="Para:" body={this.actionBodyTemplateChip}></Column>
                            <Column ></Column>
                        </DataTable>
                    </div>
                    {dialogNotificacion}
                    {newNotification}
                </div>
            </div>
        );
    }
}
export default NotificacionesCatalog;
