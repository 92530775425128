import React, { Component } from 'react';
import { InputSwitch } from 'primereact/inputswitch';
import { classNames } from 'primereact/utils';
import {SepMounstrosamenteService} from "../../service/sep_mounstrosamente/SepMounstrosamenteService";
import {Toast} from "primereact/toast";
import {Toolbar} from "primereact/toolbar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import { Knob } from "primereact/knob";
import {InputTextarea} from "primereact/inputtextarea";
import {isEmpty, validateEmail} from "../utils/commonFunctions";
import {Messages} from "primereact/messages";
import { Dropdown } from 'primereact/dropdown';
import 'primeflex/primeflex.css';
import {AppService} from "../../service/AppService/AppService";
import {Sep} from "../../service/sep/Sep";
import './DataTableDemo.css'
import {Badge} from "primereact/badge";
import { PickList } from 'primereact/picklist';
import './PickListDemo.css';




class AlumosCatalog extends Component {


    emptyAlumno = {
        nombre: "",
        email: "",
        username_edx: "",
        escuela_id: "",
        turno_id: "",
        nivel_id: "",
        grado: "",
        status: ""
    };


    constructor(props) {
        super(props);
        this.state = {
            list_alumnos: null,
            list_reforzamiento: [],
            list_examen: [],
            lst_seleccionado: [],
            list_examAsignado: [],
            list_refoAsignado: [],
            list_examenesCatalog: [],
            list_reforzamientosCatalog: [],
            paramsRefo: [],
            AlumnoDetailsDialog: false,
            detailGraphics: false,
            deleteProductDialog: false,
            dialogReforzamiento: false,
            dialogStatus: false,
            dialogDetailGraphics: false,
            dialogExamen: false,
            newAlumnoDialog: false,
            alumno: this.emptyAlumno,
            selectedAlumno: null,
            globalFilter: null,
            selectedEscuela: null,
            selectedTurno: null,
            selectedNivel: null,
            selectedNewEscuela: null,
            selectedNewTurno: null,
            selectedNewNivel: null,
            selectedFilterEscuela: null,
            listInvoiceFiltered: [],
            avance: "",
            selectedActive: false
        }
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.actionSecundaryBodyTemplate = this.actionSecundaryBodyTemplate.bind(this);
        this.actionStatusTemplate = this.actionStatusTemplate.bind(this);
        this.switchTemplate = this.switchTemplate.bind(this);
        this.openNew = this.openNew.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.hideDialog_detailAlumnos = this.hideDialog_detailAlumnos.bind(this);
        this.saveAlumno = this.saveAlumno.bind(this);
        this.editAlumno = this.editAlumno.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onEscuelaChange = this.onEscuelaChange.bind(this);
        this.onTurnoChange = this.onTurnoChange.bind(this);
        this.onNivelChange = this.onNivelChange.bind(this);
        this.deleteProduct = this.deleteProduct.bind(this);
        this.confirmDeleteProduct = this.confirmDeleteProduct.bind(this);
        this.filterListalumnos = this.filterListalumnos.bind(this);
        this.actionBodyReforzamientoTemplate = this.actionBodyReforzamientoTemplate.bind(this)
        this.actionBodyTemplateStatus = this.actionBodyTemplateStatus.bind(this)
        this.actionBodyExamenTemplate = this.actionBodyExamenTemplate.bind(this)
        this.clickReforzamientos = this.clickReforzamientos.bind(this);
        this.clickExamenes = this.clickExamenes.bind(this);
        this.onChangeRefo = this.onChangeRefo.bind(this);
        this.onChangeExamen = this.onChangeExamen.bind(this);
        this.onChangeFilter = this.onChangeFilter.bind(this);
        this.unEnroll_lst = this.unEnroll_lst.bind(this);
        this.enableStatus = this.enableStatus.bind(this);
        this.disableStatus = this.disableStatus.bind(this);
        this.unEnroll_lstExam = this.unEnroll_lstExam.bind(this);
        this.buscarUnroll = this.buscarUnroll.bind(this);
        this.buscarEnroll = this.buscarEnroll.bind(this);
        this.buscarUnroll_exam = this.buscarUnroll_exam.bind(this);
        this.buscarEnroll_exam = this.buscarEnroll_exam.bind(this);
        this.rowExpansionGraphics = this.rowExpansionGraphics.bind(this);
        this.detailGraphiscs = this.detailGraphiscs.bind(this);
        this.filterList = this.filterList.bind(this);





        this.sep = new SepMounstrosamenteService();
        this.appservice = new AppService();
        this.sepService = new Sep();
    }

    componentDidMount() {
        this.filterList();
        this.sep.getCatalogAlumnos().then(resp => {
            const list_escuelas = resp.data.escuelas;
            const list_turnos = resp.data.turnos;
            const list_niveles = resp.data.niveles;
            this.setState({list_escuelas,
            list_turnos,
            list_niveles});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading escuelas list",
                life: 5000
            })
        });
    }

    onEscuelaChange(e) {
        debugger
        this.setState({selectedEscuela: e.value});
        this.setState({selectedNewEscuela: e.value});
        debugger
        console.log("selectedEscuela")
        console.log(this.state.selectedEscuela)
    }
    onChangeFilter(e) {
        this.setState({selectedFilterEscuela: e.value});

    }

    filterList () {
        this.sep.postListAlumnos({"escuela_id": null}).then(resp => {
            const list_alumnos = resp.data;
            this.setState({list_alumnos});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading alumnos list",
                life: 5000
            })
        });
    }



    onTurnoChange(e) {
        this.setState({selectedTurno: e.value});
        this.setState({selectedNewTurno: e.value});
    }


    onNivelChange(e) {
        this.setState({selectedNivel: e.value});
        this.setState({selectedNewNivel: e.value});
    }

    openNew() {
        this.setState({
            selectedFilerEscuela: null,
            alumno: this.emptyAlumno,
            submitted: false,
            displaynewAlumno: true,
        });
    }


    leftToolbarTemplate(rowData) {
        return (
            <React.Fragment>
                <Button label="Nuevo Alumno" icon="pi pi-plus" className="p-button-raised p-button-success p-mr-2"
                        onClick={() => this.openNew(rowData)}/>
            </React.Fragment>
        )
    }

    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button  label="Agregar Lista De Alumnos" icon="pi pi-plus" className="p-button-raised p-button-help"/>
                <Button style={{marginLeft: '10px'}} label="Export" icon="pi pi-upload" className="p-button-raised p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }


    filterListalumnos() {
        let filterescuela = {...this.state.filterescuela};
        filterescuela.escuela_id = this.state.selectedFilterEscuela.id;
        this.sep.postListAlumnos({"escuela_id": this.state.selectedFilterEscuela.id}).then(resp => {
            const list_alumnos = resp.data;
            this.setState({list_alumnos});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading alumnos list",
                life: 5000
            })
        });
    }

    confirmDeleteProduct(alumno) {
        this.setState({
            alumno,
            deleteProductDialog: true
        });
    }

    deleteProduct() {
        this.setState({deleteProductDialog: false});
        debugger
        const params_refo_id = this.state.alumno.reforzamiento_desc;
        const params_examen_id = this.state.alumno.examen_desc;
         if (params_refo_id !== undefined) {
             debugger
             const params = {
                 "email": this.state.email,
                 "reforzamiento_id": params_refo_id.id
             };

             this.sep.postUnrollRefo(params).then(resp => {
                 const respuesta = resp.status;
                 console.log(respuesta)
                 let list_ref = this.state.list_ref.filter(val => val.id !== this.state.alumno.id);
                 this.setState({
                     list_ref,
                     deleteProductDialog: false,
                     alumno: this.emptyAlumno
                 });
                 this.toast.show({severity: 'success', summary: 'Successful', detail: 'Reforzamiento Eliminado', life: 3000});
             }).catch(error => {
                 this.toast.show({
                     severity: 'error',
                     summary: 'Error',
                     detail: "Error Unroll reforzamiento",
                     life: 5000
                 })
             });
         } else {
             const params = {
                 "email": this.state.email,
                 "examen_id": params_examen_id.id
             };
             this.sep.postUnrollExam(params).then(resp => {
                 const respuesta = resp.status;
                 console.log(respuesta)
                 let list_examRef = this.state.list_examRef.filter(val => val.id !== this.state.alumno.id);
                 this.setState({
                     list_examRef,
                     deleteProductDialog: false,
                     alumno: this.emptyAlumno
                 });
                 this.toast.show({severity: 'success', summary: 'Successful', detail: 'Exámen Eliminado', life: 3000});
             }).catch(error => {
                 this.toast.show({
                     severity: 'error',
                     summary: 'Error',
                     detail: "Error Unroll exámen",
                     life: 5000
                 })
             });
         }
    }


    exportCSV() {
        this.dt.exportCSV();
    }

    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success p-mr-2"
                        onClick={() => this.editAlumno(rowData)}/>
            </React.Fragment>
        );
    }

    actionBodyTemplateStatus(rowData) {
        return (
            <React.Fragment>
                <Button icon="fa fa-check-square-o" className="p-button-rounded p-button-info p-mr-2"
                        onClick={() => this.clickStatus(rowData)}/>
            </React.Fragment>
        );
    }

    actionBodyExamenTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-book" className="p-button-raised p-button-rounded p-button-warning p-mr-2" onClick={() => this.clickExamenes(rowData)}/>
            </React.Fragment>
        );
    }

    actionBodyReforzamientoTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-copy" className="p-button-raised p-button-rounded p-button-warning p-mr-2" onClick={() => this.clickReforzamientos(rowData)}/>
            </React.Fragment>
        );
    }



    actionSecundaryBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning"
                        onClick={() => this.confirmDeleteProduct(rowData)}/>
            </React.Fragment>
        );
    }

    saveAlumno() {


        debugger
        let state = {submitted: true};
        let list_alumnos = [...this.state.list_alumnos];
        let alumno = {...this.state.alumno};
        // validate alumnos

        const errors = [];
        if (isEmpty(alumno.nombre)) {
            errors.push({severity: 'error', summary: '', detail: 'Nombre is mandatory', life: 4000})
        }
        if (!isEmpty(alumno.email) && !validateEmail(alumno.email)) {
            errors.push({severity: 'error', summary: '', detail: 'Email format is wrong', life: 4000})
        }
        if (isEmpty(alumno.username_edx)) {
            errors.push({severity: 'error', summary: '', detail: 'UserName format is wrong', life: 4000})
        }
        if (isEmpty(alumno.grado)) {
            errors.push({severity: 'error', summary: '', detail: 'Grado format is wrong', life: 4000})
        }

        if (errors.length > 0) {
            this.msgs.show(errors);
            return;
        } else {

            alumno.escuela_id = this.state.selectedNewEscuela.id
            alumno.turno_id = this.state.selectedNewTurno.id
            alumno.nivel_id = this.state.selectedNewNivel.id

            this.sep.newAlumno(alumno).then(resp => {
                debugger
                state = {
                    ...state,
                    list_alumnos,
                    newAlumnoDialog: false,
                    alumno: this.emptyAlumno
                };
                list_alumnos.push(alumno);
                this.setState(state);
                this.toast.show({severity: 'success', summary: 'Successful', detail: 'Alumno Created', life: 5000});
                this.hideDialog()
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error saving newAlumno",
                    life: 5000
                })
            });
        }
        if (this.state.alumno.id) {
            this.sep.updateEcommerce(alumno).then(resp => {
                debugger
                state = {
                    ...state,
                    list_alumnos,
                    AlumnoDetailsDialog: false,
                    alumno: this.emptyAlumno
                };
                this.setState(state);
                this.toast.show({severity: 'success', summary: 'Successful', detail: 'alumno Updated', life: 5000});
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error update alumno",
                    life: 5000
                })
            });
        }
    }

    editAlumno(alumno) {
        const params = {
            "username": alumno.username_edx
        }
        const email = alumno.email;
        this.setState({email});
        this.state.list_turnos.forEach((turno, index) => {
            if (turno.id === alumno.turno) {
                return this.setState({selectedTurno: turno});
            }
        });

        this.state.list_niveles.forEach((nivel, index) => {
            if (nivel.id === alumno.nivel) {
                return this.setState({selectedNivel: nivel});
            }
        });


        this.appservice.postExamAndReinforcement(params).then(resp => {
            if (resp.status === 'success') {

                const list_examRef = resp.data.examenes;
                const list_ref = resp.data.reforzamientos;
                this.setState({list_examRef});
                this.setState({list_ref});
                console.log("list_ref")
                console.log(list_ref)
                console.log("list_examRef")
                console.log(list_examRef)

            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Fail get list Invoices',
                    life: 5000
                });
            }

        }).catch(error => {
            const list_examRef = [];
            const list_ref = [];
            this.setState({list_ref})
            this.setState({list_examRef});
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading Examenes y Reforzamientos Asignados",
                life: 5000
            })
        });

        this.setState({
            alumno: {...alumno},
            AlumnoDetailsDialog: true,


        });
    }
    enableStatus (rowData) {
        const email = this.state.rowData.email
        const params = {
            "email": email,
            "enable": true
        };
        this.sep.postBajaLogica(params).then(resp => {
            }
        );
        this.hideDialog();
        this.filterList();
        window.location.reload(true);
    }

    disableStatus (rowData) {
        const email = this.state.rowData.email
        const params = {
            "email": email,
            "enable": false
        };
        this.sep.postBajaLogica(params).then(resp => {
            }
            );
        this.hideDialog();
        this.filterList();
        window.location.reload(true);
    }

    hideDialog() {
        this.setState({
            submitted: false,
            displaynewAlumno: false,
            AlumnoDetailsDialog: false,
            dialogReforzamiento: false,
            dialogStatus: false,
            dialogExamen: false
        });
    }
    hideDialog_detailAlumnos () {
        this.setState({
            deleteProductDialog: false,
            detailGraphics: false,
        });
    }


    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let alumno = {...this.state.alumno};
        alumno[`${name}`] = val;

        this.setState({alumno});
    }

    imageBodyTemplate(rowData) {
        return <img src={`https://lms-test.aefcm.gob.mx${rowData.examen_desc.edx_course_image}`}
                    onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                    alt={rowData.image} className="product-image"/>;
    }

    imageBodyTemplateR(rowData) {
        return <img src={`https://lms-test.aefcm.gob.mx${rowData.reforzamiento_desc.edx_course_image}`}
                    onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}
                    alt={rowData.image} className="product-image"/>;
    }



    actionStatusTemplate(rowData) {
        return (
            <React.Fragment>
                <Badge value={rowData.status} className={this.rowClass(rowData)}></Badge>
            </React.Fragment>
        )
    }

    switchTemplate(rowData) {
        let statusBoolean = false;
        if (rowData.status==="1") {
             statusBoolean = true
        }
        return (
            <React.Fragment>
                <InputSwitch checked={statusBoolean}  />
            </React.Fragment>
        );
    }

    clickReforzamientos (rowData) {
        const list_refoAsignado = [];
        const list_refoAsignadoAnt = [];
        this.setState({list_refoAsignado});
        this.setState({list_refoAsignadoAnt});
        this.setState({dialogReforzamiento: true});
        this.setState({rowData});
        const params = {
            "username": rowData.username_edx,
            "email": rowData.email
        }
        debugger
        this.appservice.postExamAndReinforcement(params).then(resp => {
            if (resp.status === 'success') {
                const list_reforzamiento = resp.data.reforzamientos;
                this.setState({list_reforzamiento});
                list_reforzamiento.forEach(x => {
                    const dict_reforzamiento = x.reforzamiento_desc;
                    list_refoAsignado.push(dict_reforzamiento)
                    list_refoAsignadoAnt.push(dict_reforzamiento)
                });
                debugger
                this.setState(list_refoAsignado);
                this.setState(list_refoAsignadoAnt);
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Fail get list Invoices',
                    life: 5000
                });
            }
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading reforzamientos",
                life: 5000
            })
        });
        this.sep.getReforzamientosCatalog(params).then(resp => {
            const list_reforzamientosCatalog = resp.data;
            this.setState({list_reforzamientosCatalog});
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading Exam Catalog",
                life: 5000
            })
        });

    }

    clickStatus (rowData) {
        this.setState({dialogStatus: true});
        this.setState({rowData});

    }
    clickExamenes (rowData) {
        debugger
        const list_examAsignado = [];
        const list_examAsignadoAnt = [];
        this.setState({list_examAsignadoAnt})
        this.setState({list_examAsignado});
        this.setState({dialogExamen: true});
        this.setState({rowData});
        const params = {
            "username": rowData.username_edx,
            "email": rowData.email
        }
        this.appservice.postExamAndReinforcement(params).then(resp => {
            if (resp.status === 'success') {
                const list_examen = resp.data.examenes;
                list_examen.forEach(x => {
                    const dict_examen = x.examen_desc;
                    list_examAsignado.push(dict_examen);
                    list_examAsignadoAnt.push(dict_examen);

                });
                this.setState(list_examAsignado);
                this.setState(list_examAsignadoAnt);
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Fail get list Invoices',
                    life: 5000
                });
            }
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading examenes",
                life: 5000
            })
        });
        this.sep.getExamenesCatalog(params).then(resp => {
            const list_examenesCatalog = resp.data;
            this.setState({list_examenesCatalog});
            console.log("List_examenescatalog")
            console.log(list_examenesCatalog)
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading Exam Catalog",
                life: 5000
            })
        });

    }
    onChangeRefo(event) {
        this.setState({
            list_refoAsignado: event.target,
            list_reforzamientosCatalog: event.source
        });
        debugger
    }


    onChangeExamen(event) {
        this.setState({
            list_examAsignado: event.target,
            list_examenesCatalog: event.source
        });
        debugger
    }



    unEnroll_lst () {
        const list_unenroll = [];
        const list_enroll = [];
        let list_refoAsignado_Ant = this.state.list_refoAsignadoAnt;
        let list_refoAsignado = this.state.list_refoAsignado;
        debugger
        this.state.list_refoAsignado.forEach(x1 => {
            const dict_recorrido = x1;
            this.setState(dict_recorrido);
            if (this.buscarEnroll(dict_recorrido, list_refoAsignado_Ant) === true) { console.log ("Reforzamiento existente esta en ambas listas");
            } else {
                list_enroll.push(dict_recorrido.id)
                this.setState(list_enroll)
                console.log("lista de refo enroll")
                console.log(list_enroll)
            }
        });
        debugger
        this.state.list_refoAsignadoAnt.forEach(x2 => {
            const dict_recorridoUn = x2;
            this.setState(dict_recorridoUn);
            if (this.buscarUnroll(dict_recorridoUn, list_refoAsignado) === true) { console.log ("Reforzamiento existente esta en ambas listas");
            } else {
                list_unenroll.push(dict_recorridoUn.id)
                this.setState(list_unenroll)
                console.log("lista de refo enroll")
                console.log(list_unenroll)
            }
        });
        const params = {
            "email": this.state.rowData.email,
            "reforzamiento_enroll_ids": list_enroll,
            "reforzamiento_unenroll_ids": list_unenroll
        }
        this.sep.postUnEnrollsReforzamientos(params).then(resp => {
            const respuesta = resp.status;
            console.log("respuesta")
            console.log(respuesta)
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error unEnroll Reforzamientos",
                life: 5000
            })
        });
        console.log("list_new")
        console.log(this.state.list_ref)
        this.hideDialog();
    }


    buscarEnroll (dict_recorrido, list_refoAsignado_Ant) {

        const dict_ant = this.state.list_refoAsignadoAnt;
        for (let i = 0; i < dict_ant.length; i++) {
            if (dict_ant[i].id === dict_recorrido.id)
                return true;
            }
        return false;
    }

    buscarUnroll (dict_recorridoUn, list_refoAsignado) {
        const dict_ant = this.state.list_refoAsignado;
        for (let i = 0; i < dict_ant.length; i++) {
            if (dict_ant[i].id === dict_recorridoUn.id)
                return true;
        }
        return false;
    }

    unEnroll_lstExam (rowData) {
        const list_unrollExam = [];
        const list_enrollExam = [];
        let list_examAsignado_Ant = this.state.list_examAsignadoAnt;
        let list_examAsignado = this.state.list_examAsignado;
        debugger
        this.state.list_examAsignado.forEach(x1 => {
            const dict_recorrido = x1;
            this.setState(dict_recorrido);
            if (this.buscarEnroll_exam(dict_recorrido, list_examAsignado_Ant) === true) { console.log ("Exámen existente esta en ambas listas");
            } else {
                list_enrollExam.push(dict_recorrido.id)
                this.setState(list_enrollExam)
                console.log("lista de refo enroll")
                console.log(list_enrollExam)
            }
        });
        debugger
        this.state.list_examAsignadoAnt.forEach(x2 => {
            const dict_recorridoUn = x2;
            this.setState(dict_recorridoUn);
            if (this.buscarUnroll_exam(dict_recorridoUn, list_examAsignado) === true) { console.log ("Exámen existente esta en ambas listas");
            } else {
                list_unrollExam.push(dict_recorridoUn.id)
                this.setState(list_unrollExam)
                console.log("lista de refo enroll")
                console.log(list_unrollExam)
            }
        });
        const params = {
            "email": this.state.rowData.email,
            "examen_enroll_ids": list_enrollExam,
            "examen_unenroll_ids": list_unrollExam
        }
        this.sep.postUnEnrollsExamenes(params).then(resp => {
            const respuesta = resp.status;
            console.log("respuesta")
            console.log(respuesta)
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error unEnroll Examenes ",
                life: 5000
            })
        });
        this.hideDialog();
    }
    buscarEnroll_exam (dict_recorrido, list_examAsignado_Ant) {
        const dict_ant = this.state.list_examAsignadoAnt;
        for (let i = 0; i < dict_ant.length; i++) {
            if (dict_ant[i].id === dict_recorrido.id)
                return true;
        }
        return false;
    }

    buscarUnroll_exam (dict_recorridoUn, list_examAsignado) {
        const dict_ant = this.state.list_examAsignado;
        for (let i = 0; i < dict_ant.length; i++) {
            if (dict_ant[i].id === dict_recorridoUn.id)
                return true;
        }
        return false;
    }

    detailGraphiscs (rowData) {

        return (
            <React.Fragment>
                <Button icon="pi pi-chart-bar" className="p-button-rounded p-button-help"
                        onClick={() => this.rowExpansionGraphics(rowData)}/>
            </React.Fragment>
        );
    }
    rowExpansionGraphics(rowData) {
        const params_examen_id = rowData.examen_desc;
        const params_refo_id = rowData.reforzamiento_desc;
        if (params_examen_id !== undefined) {
       const params = {
            "username": this.state.alumno.username_edx,
            "examen_id": params_examen_id.id
       };
        this.sepService.postAvanceExamen(params).then(resp => {
            const avance = resp.data;
            this.setState(avance);
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error loading Avance",
                life: 5000
            })
        });
        } else {
            const params = {
                "username": this.state.alumno.username_edx,
                "reforzamiento_id": params_refo_id.id
            };
            this.sepService.postAvanceReforzamiento(params).then(resp => {
                const avance = resp.data;
                this.setState(avance);
            }).catch(error => {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Error loading Avance",
                    life: 5000
                })
            });
        }
      this.setState(
          {detailGraphics: true}
      );
    }

    render() {

        const itemTemplateReforzamiento = (item) => {

            return (
                <div className="product-item">
                    <div className="image-container">
                        <img  src={`https://lms-test.aefcm.gob.mx${item.edx_course_image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}  alt={item.image} style={{width: '100px'}}/>
                    </div>
                    <div className="product-list-detail">
                        <h5 className="mb-2">{item.edx_name}</h5>
                        <i className="pi pi-copy product-category-icon"></i>
                        <span className="product-category">{item.nombre}</span>
                    </div>
                </div>
            );
        }
        const itemTemplateExamen = (item) => {

            return (
                <div className="product-item">
                    <div className="image-container">
                        <img  src={`https://lms-test.aefcm.gob.mx${item.edx_course_image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}  alt={item.image} style={{width: '100px'}}/>
                    </div>
                    <div className="product-list-detail">
                        <h5 className="mb-2">{item.edx_name}</h5>
                        <i className="pi pi-copy product-category-icon"></i>
                        <span className="product-category">{item.nombre}</span>


                    </div>
                </div>
            );
        }

        const header = (
            <div className="table-header">
                <div>
                    <React.Fragment>
                        <Dropdown options={this.state.list_escuelas} style={{width: '250px'}}  value={this.state.selectedFilterEscuela}
                                  onChange={this.onChangeFilter} optionLabel="nombre" placeholder="Escuela"/>
                        <Button  icon="pi p-button-icon pi-search" className="p-button-raised p-button-rounded" style={{marginLeft: '10px'}} onClick={this.filterListalumnos}/>
                    </React.Fragment>
                   </div>
                <span className="p-input-icon-left">
                    <i className="p-button-raised pi pi-search"/>
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Buscar..."/>
                </span>
            </div>
        );


        const newAlumnoDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveAlumno}/>
            </React.Fragment>
        );

        const editAlumnoDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.hideDialog}/>
            </React.Fragment>
        );

        const statusFooter = (
            <React.Fragment>
                <Button label="Deshabilitar" icon="pi pi-times" className="p-button-text" onClick={this.disableStatus}/>
                <Button label="Habilitar" icon="pi pi-check" className="p-button-text" onClick={this.enableStatus}/>
            </React.Fragment>
        );

        const editExamenDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.unEnroll_lstExam}/>
            </React.Fragment>
        );

        const editReforzamientoDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog}/>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.unEnroll_lst}/>
            </React.Fragment>
        );

        const deleteProductDialogFooter = (
            <React.Fragment>
                <Button label="No" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog_detailAlumnos}/>
                <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={this.deleteProduct}/>
            </React.Fragment>
        );

        const dialogDetailGraphics = (
            <Dialog visible={this.state.detailGraphics} style={{width: '150px'}} header="Porcentaje De Avance"
                     onHide={this.hideDialog_detailAlumnos}>
                            <Knob
                                value={this.state.avance}
                                valueTemplate={"{value}%"}
                            />
            </Dialog>
        );

        const dialogAlumnoDetails = (
            <Dialog visible={this.state.AlumnoDetailsDialog} style={{width: '1400px'}} header="Detalles Alumno" modal
                    className="p-fluid" footer={editAlumnoDialogFooter} onHide={this.hideDialog}>
                <div className="col-12">
                    <Messages ref={(el) => this.msgs = el}/>
                </div>
                <div className="p-field" style={{display: "none"}}>
                    <label htmlFor="id">id</label>
                    <InputText id="id" value={this.state.alumno.id}
                               onChange={(e) => this.onInputChange(e, 'id')}
                               required autoFocus
                               className={classNames({'p-invalid': this.state.submitted && !this.state.alumno.id})}/>
                    {this.state.submitted && !this.state.alumno.id &&
                        <small className="p-error">id is required.</small>}
                </div>
                <div className="p-grid p-align-center">
                    <div className="p-col">
                        <label htmlFor="nombre">Nombre</label>
                        <InputTextarea id="nombre" value={this.state.alumno.nombre}
                                       onChange={(e) => this.onInputChange(e, 'nombre')} required rows={2} cols={20}/>
                    </div>
                    <div className="p-col">
                        <label htmlFor="username_edx">username edx</label>
                        <InputText id="username_edx" value={this.state.alumno.username_edx}
                                   onChange={(e) => this.onInputChange(e, 'username_edx')} required rows={2} cols={20}/>
                    </div>
                    <div className="p-col">
                        <label htmlFor="email">Email</label>
                        <InputText id="email" value={this.state.alumno.email}
                                   onChange={(e) => this.onInputChange(e, 'email')}/>
                    </div>
                </div>
                <div className="p-grid">
                    <div className="p-col">
                        <div className="p-field p-col">
                            <label htmlFor="turno">Turno</label>
                            <div>
                                <Dropdown options={this.state.list_turnos} value={this.state.selectedTurno}
                                          onChange={this.onTurnoChange} optionLabel="nombre" placeholder="Turno"/>
                            </div>
                        </div>
                    </div>

                    <div className="p-col">
                        <div className="p-field p-col">
                            <label htmlFor="nivel">Nivel</label>
                            <div>
                                <Dropdown options={this.state.list_niveles} value={this.state.selectedNivel}
                                          onChange={this.onNivelChange} optionLabel="nombre" placeholder="Nivel"/>
                            </div>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-field p-col">
                            <label htmlFor="grado">Grado</label>
                            <InputText id="grado" value={this.state.alumno.grado}
                                       onChange={(e) => this.onInputChange(e, 'grado')}/>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-field p-col">
                            <label htmlFor="is_enroll">is enroll</label>
                            <InputText id="is_enroll" value={this.state.alumno.is_enroll}
                                       onChange={(e) => this.onInputChange(e, 'is_enroll')}/>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-field p-col">
                            <label htmlFor="status">Status</label>
                            <InputText id="status" value={this.state.alumno.status}
                                       onChange={(e) => this.onInputChange(e, 'status')}/>
                        </div>
                    </div>
                    <div className="p-col">
                        <div className="p-field p-col">
                            <label htmlFor="dcreated">Dcreated</label>
                            <InputText id="dcreated" value={this.state.alumno.dcreated}
                                       onChange={(e) => this.onInputChange(e, 'dcreated')}/>
                        </div>
                    </div>
                </div>
                <div className="grid datatable-crud-demo">
                    <div className="card">
                        <DataTable value={this.state.list_examRef} responsiveLayout="scroll" showGridlines header="Exámenes" dataKey="id" paginator rows={10}  rowsPerPageOptions={[5, 10, 25]}
                                   paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords} exámenes">
                            <Column body={this.detailGraphiscs} ></Column>
                            <Column field="examen_desc.nombre" header="Exámen"></Column>
                            <Column field="examen_desc.edx_label" header="edx label"></Column>
                            <Column field="examen_desc.edx_id" header="edx id"></Column>
                            <Column header="Image" body={this.imageBodyTemplate} className="datatable-crud-demo. product-image"></Column>
                            <Column body={this.actionSecundaryBodyTemplate} exportable={false} className="datatable-crud-demo. product-image"></Column>
                        </DataTable>
                    </div>

                    <div/>

                    <div className="datatable-crud-demo">
                        <div className="card">
                            <DataTable value={this.state.list_ref} responsiveLayout="scroll" showGridlines header="Reforzamientos" dataKey="id" paginator rows={10}   rowsPerPageOptions={[5, 10, 25]}
                                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} reforzamientos">
                                <Column body={this.detailGraphiscs} ></Column>
                                <Column field="reforzamiento_desc.nombre" header="Reforzamiento"></Column>
                                <Column field="reforzamiento_desc.edx_label" header="edx label"></Column>
                                <Column field="reforzamiento_desc.edx_id" header="edx id"></Column>
                                <Column header="Image" body={this.imageBodyTemplateR} className="datatable-crud-demo. product-image"></Column>
                                <Column body={this.actionSecundaryBodyTemplate} exportable={false} className="datatable-crud-demo. product-image"></Column>
                            </DataTable>
                        </div>
                    </div>
                </div>

                <Dialog visible={this.state.deleteProductDialog} style={{width: '450px'}} header="Confirm" modal
                        footer={deleteProductDialogFooter} onHide={this.hideDialog_detailAlumnos}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '2rem'}}/>
                        {this.state.alumno && <span>Are you sure you want to delete <b>{this.state.alumno.nombre}</b>?</span>}
                    </div>
                </Dialog>


            </Dialog>
        );

        const dialogExamenes = (
            <Dialog visible={this.state.dialogExamen}  style={{width: '1400px'}} header="Exámenes" modal
                    className="picklist-demo" footer={editExamenDialogFooter}  onHide={() => this.onHide('dialogExamen')}>
                <div className="card">
                    <PickList source={this.state.list_examenesCatalog} target={this.state.list_examAsignado} itemTemplate={itemTemplateExamen}
                              sourceHeader="Disponibles" targetHeader="Asignados"
                              sourceStyle={{ height: '345px' }} targetStyle={{ height: '345px' }}
                              onChange={this.onChangeExamen}></PickList>
                </div>
            </Dialog>
        );

        const dialogBaja = (
            <Dialog visible={this.state.dialogStatus}  style={{width: '640px'}} header="Status" modal
                     footer={statusFooter}  onHide={() => this.onHide('dialogStatus')}>
                <div className="card">
                    <p className="p-m-0">Habilitar o deshabilitar</p>
                </div>
        </Dialog>
        );

        const dialogReforzamientos = (
            <div>
            <Dialog visible={this.state.dialogReforzamiento}  style={{width: '1400px'}} header="Reforzamientos" modal
                    className="picklist-demo" footer={editReforzamientoDialogFooter}  onHide={() => this.onHide('dialogReforzamiento')}>
                <div className="card">
                    <PickList source={this.state.list_reforzamientosCatalog} target={this.state.list_refoAsignado} itemTemplate={itemTemplateReforzamiento}
                              sourceHeader="Disponibles" targetHeader="Asignados"
                              sourceStyle={{ height: '345px' }} targetStyle={{ height: '345px' }}
                              onChange={this.onChangeRefo}></PickList>
                </div>
            </Dialog>
            </div>
        );


        const newAlumno = (
            <Dialog header="Nuevo Alumno" visible={this.state.displaynewAlumno} style={{width: '450px'}}
                    footer={newAlumnoDialogFooter} modal className="p-fluid"
                    onHide={() => this.onHide('displaynewAlumno')}>
                <div className="p-col">
                    <Messages ref={(el) => this.msgs = el}/>
                </div>

                <div className="p-field">
                    <label htmlFor="nombre">Nombre</label>
                    <InputText id="nombre" value={this.state.alumno.nombre}
                               onChange={(e) => this.onInputChange(e, 'nombre')}/>
                </div>

                <div className="p-field">

                    <label htmlFor="Email">Email</label>
                    <InputText id="Email" value={this.state.alumno.email}
                               onChange={(e) => this.onInputChange(e, 'email')}/>
                </div>
                <div className="p-field">
                    <label htmlFor="username_edx">UserName</label>
                    <InputText id="username_edx" value={this.state.alumno.username_edx}
                               onChange={(e) => this.onInputChange(e, 'username_edx')}/>
                </div>
                <div className="p-field">
                    <Dropdown options={this.state.list_escuelas} value={this.state.selectedNewEscuela}
                              onChange={this.onEscuelaChange} optionLabel="nombre" placeholder="Escuela"/>
                </div>
                <div className="p-field">
                    <Dropdown options={this.state.list_turnos} value={this.state.selectedNewTurno}
                              onChange={this.onTurnoChange} optionLabel="nombre" placeholder="Turno"/>
                </div>
                <div className="p-field">
                    <Dropdown options={this.state.list_niveles} value={this.state.selectedNewNivel}
                              onChange={this.onNivelChange} optionLabel="nombre" placeholder="Nivel"/>
                </div>
                <div className="p-field">
                    <label htmlFor="Grado">Grado</label>
                    <InputText id="Grado" value={this.state.alumno.grado}
                               onChange={(e) => this.onInputChange(e, 'grado')}/>
                </div>
            </Dialog>
        );

            return (
                <div>
                    <Toast ref={(el) => this.toast = el}/>
                    <div className="grid datatable-crud-demo">
                        <div className="col-12 m-t-20">
                            <Toolbar className="p-mb-4" left={this.leftToolbarTemplate}
                                     right={this.rightToolbarTemplate}></Toolbar>
                        </div>
                        <div className="col-12">
                            <DataTable ref={(el) => this.dt = el} value={this.state.list_alumnos}
                                       selection={this.state.selectedAlumno}
                                       onSelectionChange={(e) => this.setState({selectedAlumno: e.value})}
                                       dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                       currentPageReportTemplate="Showing {first} to {last} of {totalRecords} alumno"
                                       globalFilter={this.state.globalFilter} header={header} responsiveLayout="scroll">
                                <Column field="id" header="id" sortable style={{display: "none"}}></Column>
                                <Column body={this.actionBodyTemplate} exportable={false}></Column>
                                <Column field="nombre" header="Nombre" sortable></Column>
                                <Column field="email" header="Email" ></Column>
                                <Column field="username_edx" header="Username" ></Column>
                                <Column field="turno" header="Turno" ></Column>
                                <Column field="nivel" header="Nivel" sortable></Column>
                                <Column field="grado" header="Grado" sortable></Column>
                                <Column  header="Status" body={this.switchTemplate}/>
                                <Column body={this.actionBodyTemplateStatus} exportable={false}></Column>
                                <Column  header="Exámenes" body={this.actionBodyExamenTemplate}  exportable={false}></Column>
                                <Column  header="Reforzamientos" body={this.actionBodyReforzamientoTemplate} exportable={false}></Column>

                            </DataTable>

                        </div>
                        {dialogDetailGraphics}
                        {dialogAlumnoDetails}
                        {newAlumno}
                        {dialogReforzamientos}
                        {dialogBaja}
                        {dialogExamenes}
                    </div>
                </div>
            );
        }
    }


export default AlumosCatalog;
